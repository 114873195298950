import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/styles/withStyles";
import {connect} from "react-redux";
import LoadingScreen from "../LoadingScreen";
import Typography from "@material-ui/core/Typography";
import {Divider} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Bar} from "react-chartjs-2";

const styles = theme => ({
    paper: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
    header: {
        marginBottom: theme.spacing(0.5),
    },
    total: {
        textAlign: "center",
        padding: theme.spacing(1),
    },
});

class DailyTotalSalesChart extends Component {

    constructor(props) {
        super(props);

        this.state = {type: 0}
    }

    render() {
        if (this.props.stats[this.props.eventId] === undefined) {
            return (
                <Paper className={this.props.classes.paper}>
                    <Grid container style={{justifyContent: "flex-start", alignItems: "center"}}>
                        <Grid>
                            <Typography variant={"h6"} className={this.props.classes.header}>
                                Denní obraty
                            </Typography>
                        </Grid>
                    </Grid>
                    <LoadingScreen reason={"Zpracovávám data..."}/>
                </Paper>
            );
        }

        let sales = {};

        let labels = [];
        let cardData = [];
        let cashData = [];

        console.log(this.props.stats[this.props.eventId].total);

        this.props.stats[this.props.eventId].total.forEach(t => {
            let d = new Date(t.date);
            let date = d.getDate() + ". " + (d.getMonth() + 1) + " " + d.getFullYear();

            if (sales[date] === undefined) {
                sales[date] = {cashAmount: 0, cardAmount: 0};
            }

            if (t.payType === 'CASH') {
                sales[date].cashAmount = t.amount;
            } else if (t.payType === 'CREDIT_CARD') {
                sales[date].cardAmount = t.amount;
            }
        });

        for (let key in sales) {
            if (sales.hasOwnProperty(key)) {
                labels = [...labels, key];
                cashData = [...cashData, {x: key, y: sales[key].cashAmount}];
                cardData = [...cardData, {x: key, y: sales[key].cardAmount}];
            }
        }

        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Hotově',
                    backgroundColor: 'rgb(64,185,64, 0.6)',
                    borderColor: 'rgb(0,110,2)',
                    borderWidth: 1,
                    data: cashData
                },
                {
                    label: 'Kartou',
                    backgroundColor: 'rgb(66,146,185, 0.6)',
                    borderColor: 'rgb(21,72,110)',
                    borderWidth: 1,
                    data: cardData
                }
            ]
        };

        console.log(data)

        return (
            <Paper className={this.props.classes.paper}>
                <Grid container style={{justifyContent: "flex-start", alignItems: "center"}}>
                    <Grid>
                        <Typography variant={"h6"} className={this.props.classes.header}>
                            Denní obraty
                        </Typography>
                    </Grid>
                </Grid>

                <Bar
                    data={data}
                    options={{
                        maintainAspectRatio: true, scales: {
                            yAxes: [{
                                stacked: true,
                            }],
                            xAxes: [{
                                stacked: true,
                            }],
                        }
                    }}
                />

            </Paper>
        );
    }
}

function mapStateToProps(state) {
    const stats = state.statsList;
    return {
        stats,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(DailyTotalSalesChart))
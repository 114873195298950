import {Component} from "react";
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import history from "../history";
import {withStyles} from "@material-ui/core";
import EditProductModal from "./modals/EditProductModal";
import Dialog from "@material-ui/core/Dialog";
import EditCategoryModal from "./modals/EdiCategoryModal";
import BookmarkIcon from '@material-ui/icons/Bookmark';

const styles = theme => ({

});

class EventRow extends Component {

    constructor(props) {
        super(props);

        this.state = {open: false}
    }

    render() {
        return (
            <TableRow>
                <TableCell>{this.props.category.name}</TableCell>
                <TableCell><BookmarkIcon style={{color: this.props.category.color}}/></TableCell>
                <TableCell align="right">
                    <Button variant="contained" size="small" color="secondary" onClick={this.handleEdit}>
                        Upravit
                    </Button>
                </TableCell>
                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <EditCategoryModal category={this.props.category} handleClose={this.handleClose}/>
                </Dialog>
            </TableRow>
        );
    }
    handleEdit = e => {
        e.preventDefault();

        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };
    

}

export default withStyles(styles)(EventRow);
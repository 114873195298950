import React, {Component} from "react";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import {createEvent} from "../../actions/eventsActions";
import {addToCashDesk} from "../../actions/cashDeskActions";

const styles = theme => ({
    modalGrid: {
        padding: theme.spacing(3, 2),
    },
    selectMenu: {
        width: '200px',
    },
});

class AddEventModal extends Component {

    constructor(props) {
        super(props);

        this.state = {amount: 0}
    }

    render() {
        return (
            <Grid container direction={"column"} className={this.props.classes.modalGrid}>
                <Typography variant={"h4"}>
                    Hotovost
                </Typography>
                <Divider/>
                <TextField style={{margin: '10px'}}
                           id="outlined-reason"
                           label="Důvod"
                           value={this.state.reason}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="reason"
                />
                <TextField style={{margin: '10px'}}
                           id="outlined-amount"
                           label="Částka"
                           value={this.state.amount}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="amount"
                           type="number"
                />
                {this.button(this.state.amount >= 0)}
            </Grid>
        );
    }

    button = (add) => {
        if(add){
            return (
                <Button id={"modalButton"} variant="contained" size="medium" color="primary"
                        onClick={e => this.save(e)}>
                    Vložit hotovost
                </Button>
            )
        }else{
            return (
                <Button id={"modalButton"} variant="contained" size="medium" color="primary"
                        onClick={e => this.save(e)}>
                    Odvést hotovost
                </Button>
            )
        }
    };

    save = (e) => {
        e.preventDefault();

        this.props.dispatch(addToCashDesk(this.props.eventId, this.state.reason, this.state.amount));
        this.props.handleClose();
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

}


export default connect()(withStyles(styles)(AddEventModal));
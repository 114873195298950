export default function receiptsReducer(receipts = {
    data: [],
    isFetching: true,
    isFetchingAnother: false,
}, action) {
    if (action.type === 'FETCHING_RECEIPTS') {
        return Object.assign({}, receipts, {
            isFetching: true,
            data: []
        })
    }
    if (action.type === 'FETCHING_MORE_RECEIPTS') {
        return Object.assign({}, receipts, {
            isFetchingAnother: true,
        })
    }
    if (action.type === 'FETCH_RECEIPTS') {
        return Object.assign({}, receipts, {
            isFetching: false,
            data: action.payload.receipts
        })
    }
    if (action.type === 'FETCH_MORE_RECEIPTS') {
        return Object.assign({}, receipts, {
            isFetchingAnother: false,
            data: [...receipts.data, ...action.payload.receipts]
        })
    }
    if (action.type === 'RESET_RECEIPT') {
        let data = receipts.data;
        receipts.data.forEach(((value, index) => {
            if (value.id === action.payload.receiptId) {
                data[index].valid = 0
            }
        }));

        return Object.assign({}, receipts, {
            data: data,
        })
    }
    return receipts;
}
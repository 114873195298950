import {Component} from "react";
import React from "react";
import {connect, ConnectedProps} from "react-redux";
import Layout from "../../components/Layout";
import {fetchStock} from "../../actions/stockActions";
import {fetchEvents} from "../../actions/eventsActions";
import LoadingScreen from "../../components/LoadingScreen";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import {fetchProducts} from "../../actions/fetchProducts";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import withStyles from "@material-ui/core/styles/withStyles";
import {createStyles, TextField, Theme, WithStyles} from "@material-ui/core";
import {RouteComponentProps} from "react-router-dom";
import {Package} from "../../types/Packages";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ProductStockRowModal from "../modals/ProductStockRowModal";
import {addProduct} from "../../actions/productsActions";

const styles = (theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(3, 2),
    },
});

export interface PackageProductRowProps extends WithStyles<typeof styles> {
    productName: string,
    productId: number,
    amount: number,
    readOnly: boolean,
    setAmount: (productId: number, amount: number) => void,
    setTempValue: (productId: number, amount: string) => void
}


export interface PackageProductRowState {
    amount: number
}

class PackageProductRow extends Component<PackageProductRowProps,PackageProductRowState> {

    constructor(props: PackageProductRowProps){
        super(props);
        
        this.state = {amount: props.amount}
    }

    render(){
        return (
            <TableRow>
                <TableCell>
                    {this.props.productName}
                </TableCell>
                <TableCell align={"right"}>
                    <form onSubmit={this.handleSubmit}>
                    <TextField style={{margin: '3px'}}
                               id="outlined-search"
                               label="Počet"
                               value={this.state.amount}
                               onChange={e => this.handleChange(e)}
                               margin="normal"
                               variant="outlined"
                               name="amount"
                               type="number"
                               size={"small"}
                               InputProps={{
                                   readOnly: this.props.readOnly,
                               }}
                    />
                    </form>
                </TableCell>
            </TableRow>
        );
    }

    handleChange = (e) => {
        this.setState({
            amount: e.target.value
        });
        this.props.setTempValue(this.props.productId, e.target.value);
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        
        console.log(this.state.amount);
        this.props.setAmount(this.props.productId, Number(this.state.amount));
    }
    
}

export default withStyles(styles)(PackageProductRow);
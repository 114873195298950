import React, {Component} from "react";
import Layout from "../components/Layout";
import Paper from "@material-ui/core/Paper";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ReceiptInfo from "../components/ReceiptInfo";
import {fetchMoreReceipts, fetchReceipts} from "../actions/receiptsActions";
import LoadingScreen from "../components/LoadingScreen";

const styles = theme => ({
    paper: {
        padding: theme.spacing(3, 2),
    },
});

class ReceiptsTray extends Component {

    constructor(props) {
        super(props);

        if (this.props.user != null) {
            const eventId = this.props.match.params.eventId != null ? this.props.match.params.eventId : this.props.user.eventId;
            this.state = {eventId: eventId}
        } else {
            this.state = {eventId: 0}
        }

    }

    isBottom(el) {
        if (el == null) {
            return false;
        }
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    componentDidMount() {
        this.props.dispatch(fetchReceipts(this.state.eventId));
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('listOfReceipts');
        if (this.isBottom(wrappedElement)) {
            this.props.dispatch(fetchMoreReceipts(this.state.eventId));
        }
    };

    render() {
        if (this.props.receipts.isFetching) {
            return (
                <Layout>
                    <LoadingScreen reason={"Stahuji data"}/>
                </Layout>
            );
        }
        return (
            <Layout>
                <Paper id={"listOfReceipts"} className={this.props.classes.paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Čas</TableCell>
                                <TableCell align="right">Cena</TableCell>
                                <TableCell align="center">Typ platby</TableCell>
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        {this.props.receipts.data.map(receipt => {
                                return (
                                    <ReceiptInfo receipt={receipt}/>
                                );
                            }
                        )}
                    </Table>
                </Paper>
            </Layout>
        );
    }


}

function mapStateToProps(state) {
    const user = state.loggedAs;
    const events = state.eventList;
    const receipts = state.receipts;
    return {
        user,
        events,
        receipts,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(ReceiptsTray));
import {Component} from "react";
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import history from "../history";


class UserRow extends Component {

    constructor(props){
        super(props);
    }

    render(){
        return (
            <TableRow>
                <TableCell>{this.props.user.name} {this.props.user.surname} [{this.props.user.userName}]</TableCell>
                <TableCell align="right">
                    <Button variant="contained" size="small" color="secondary" onClick={this.handleEdit}>
                        Upravit
                    </Button>
                </TableCell>
            </TableRow>
        );
    }

    handleEdit = e => {
        e.preventDefault();

        history.push("/users/edit/" + this.props.user.userName);
    }

}

export default UserRow;
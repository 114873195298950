import React from 'react';
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    paper2: {
        width: '1',
        padding: theme.spacing(3, 2),
        paddingTop: theme.spacing(1)
    }
}));

export default function MysteryBox(props){
    const [checked, setChecked] = React.useState(false);
    const classes = useStyles();
    
    const handleChange = (e) => {
        setChecked(e.target.checked);
    };

    if(props.box == null){
        return (
                <Paper className={classes.paper2}>
                    <h3>Mystery box #{props.number}</h3>
                    <div>Nebylo možné sestavit tento box.</div>
                </Paper>
            
        );
    }
    
    return (
            <Paper className={classes.paper2}>
                <h3>{props.box.name} #{props.number}</h3>
                {props.box.products.map(product => {
                    return (
                        <div> {product.name} </div>
                    );
                })}
                <div>Hodnota: {props.box.value}</div>
                <Checkbox
                    name={'completed'}
                    checked={checked}
                    onChange={e => handleChange(e)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </Paper>
    );
}
import axios from "axios";

export function fetchEventsStats(eventId) {
    return async function (dispatch, state) {
        dispatch({type: 'FETCH_EVENTS_STATS'});
        axios.get('/api/stats/')
            .then(res => {
                console.log(res.data);
                dispatch({type: 'FETCHED_EVENTS_STATS', payload: {stats: res.data}})
            })
            .catch(error => {
                console.log(error);
            });
    }
}
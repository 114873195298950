import {Component} from "react";
import React from "react";
import {connect} from "react-redux";
import Layout from "../components/Layout";
import {fetchStock} from "../actions/stockActions";
import {fetchEvents} from "../actions/eventsActions";
import LoadingScreen from "../components/LoadingScreen";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import ProductStockRow from "../components/ProductStockRow";
import Typography from "@material-ui/core/Typography";
import {fetchProducts} from "../actions/fetchProducts";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme =>({
    paper: {
        padding: theme.spacing(3, 2),
    },
});

class Stock extends Component {

    constructor(props){
        super(props);

        if(this.props.user != null){
            const eventId = this.props.match.params.eventId != null ? this.props.match.params.eventId : this.props.user.eventId;
            this.state = {eventId: eventId}
        }else{
            this.state = {eventId: 0}
        }

    }

    componentDidMount() {
        this.props.dispatch(fetchProducts());
        this.props.dispatch(fetchEvents());
        if(this.state.eventId > 0){
            this.props.dispatch(fetchStock(this.state.eventId));
        }
    }

    render(){
        if(this.props.stock.isFetching || this.props.events.isFetching || this.props.products.isFetching){
            return (
                <Layout>
                    <LoadingScreen reason={"Načítám skladové údaje"}/>
                </Layout>
            );
        }
        return (
            <Layout>
                <Paper className={this.props.classes.paper}>
                    <Typography variant={"h4"}>
                        {
                            this.props.events.data.map(e => {
                            if(e.id === this.state.eventId){
                                return e.name;
                            }
                        })}
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Produkt</TableCell>
                                <TableCell align="right">Množství</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            this.props.products.data.map(product => {

                                var amount = 0;
                                this.props.stock.data.forEach(p => {
                                    if(product.id === p.productId){
                                        amount = p.amount
                                    }
                                });

                                return <ProductStockRow productName={product.name} productId={product.id} amount={amount} eventId={this.state.eventId} />;
                            })
                        }
                    </Table>
                </Paper>
            </Layout>
        );
    }
}

function mapStateToProps(state){
    const user = state.loggedAs;
    const events = state.eventList;
    const products = state.productList;
    const stock = state.stock;
    return {
        user,
        events,
        stock,
        products,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Stock));
import React, {Component} from "react";
import TableRow from "@material-ui/core/TableRow";
import {addToStock} from "../actions/stockActions";
import ProductStockRowModal from "./modals/ProductStockRowModal";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import history from "../history";

const styles = theme => ({
    cashDeskButton: {
        marginLeft: theme.spacing(1),
        backgroundColor: '#4caf50',
        color: 'white',
        '&:hover': {
            backgroundColor: '#418141',
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: '#4caf50',
            },
        },
    },
});



class ProductStockRow extends Component {

    constructor(props){
        super(props);

        this.state = {open: false}
    }

    render(){
        return (
                <TableRow>
                    <TableCell>
                        {this.props.productName}
                    </TableCell>
                    <TableCell align={"right"}>
                        {this.props.amount} ks
                    </TableCell>
                    <TableCell align={"right"}>
                        <Button variant="contained" size="small" color="secondary" onClick={this.openModal}>
                            Přidat
                        </Button>
                        <Button variant="contained" size="small" color="secondary" className={this.props.classes.cashDeskButton} onClick={this.openHistory}>
                            Historie
                        </Button>
                    </TableCell>
                    <Dialog open={this.state.open} onClose={this.handleCloseModal}>
                        <ProductStockRowModal addToStock={this.addToStock} handleClose={this.handleCloseModal}/>
                    </Dialog>
                </TableRow>
        );
    }

    addToStock = (reason, amount) => {
        this.props.dispatch(addToStock(this.props.eventId, this.props.productId, reason, amount))
    };

    openModal = (e) => {
        e.preventDefault();

        this.setState({open: true})
    };

    openHistory = (e) => {
        e.preventDefault();

        history.push("/products/history/" + this.props.eventId + "/" + this.props.productId)
    };

    handleCloseModal = () => {
        this.setState({open: false})
    };
}



export default connect()(withStyles(styles)(ProductStockRow));
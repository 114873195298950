import React, {Component} from "react";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import {addToStock} from "../../actions/stockActions";

const styles = theme => ({
    modalGrid: {
        padding: theme.spacing(3, 2),
    },
    selectMenu: {
        width: '200px',
    },
});

const reasons = ["Zásobování", "Mystery Box", "Poničené", "Propagace"];

class ProductStockRowModal extends Component {

    constructor(props) {
        super(props);

        this.state = {amount: 0, reason: reasons[0]}
    }

    render() {
        return (
            <Grid container direction={"column"} className={this.props.classes.modalGrid}>
                <Typography variant={"h4"}>
                    Přidat zboží
                </Typography>
                <Divider/>
                <TextField style={{margin: '10px'}}
                           id="outlined-select-reason"
                           select
                           label="Důvod"
                           value={this.state.reason}
                           onChange={e => this.handleChange(e)}
                           name={"reason"}
                           SelectProps={{
                               native: true,
                               MenuProps: {
                                   className: this.props.classes.selectMenu,
                               },
                           }}
                           margin="normal"
                           variant="outlined"
                >
                    {reasons.map(option => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </TextField>
                <TextField style={{margin: '10px'}}
                           id="outlined-amount"
                           label="Počet"
                           value={this.state.amount}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="amount"
                           type="number"
                />
                <Button id={"modalButton"} variant="contained" size="medium" color="secondary"
                        onClick={e => this.save(e)}>
                    Přidat zboží
                </Button>
            </Grid>
        );
    }

    save = (e) => {
        e.preventDefault();

        if (this.state.amount !== 0) {
            this.props.addToStock(this.state.reason, this.state.amount);
        }

        this.props.handleClose();
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

}


export default connect()(withStyles(styles)(ProductStockRowModal));
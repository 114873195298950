import React from 'react';
import ProductList from "../components/ProductList";
import Layout from "../components/Layout";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {fetchCategories} from "../actions/fetchCategories";
import {connect} from "react-redux";
import {fetchProducts} from "../actions/fetchProducts";

class CategoryList extends React.Component {

    classes = makeStyles(theme => ({}));

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.dispatch(fetchCategories())
        this.props.dispatch(fetchProducts())
    }

    render() {
        return (
            <Layout>
                {
                    this.props.categories.map(category => {
                        return <ProductList key={category.id} categoryId={category.id} categoryName={category.name}/>
                    })
                }
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    let categories = state.categoryList.data;
    let isFetched = !state.categoryList.isFetching;
    return {
        categories,
        isFetched
    }
}

export default connect(mapStateToProps)(CategoryList);

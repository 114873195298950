import {Component} from "react";
import Layout from "../components/Layout";
import React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import Table from "@material-ui/core/Table";
import LoadingScreen from "../components/LoadingScreen";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import EventRow from "../components/EventRow";
import Dialog from "@material-ui/core/Dialog";
import {fetchCategories} from "../actions/fetchCategories";
import AddCategoryModal from "../components/modals/AddCategoryModal";
import CategoryRow from "../components/CategoryRow";
import TableHead from "@material-ui/core/TableHead";

const styles = {
    paper: {
        width: '1',
    },
};

class Events extends Component {

    constructor(props){
        super(props);

        this.state = {open: false}
    }

    componentDidMount() {
        this.props.dispatch(fetchCategories());
    }

    render(){
        if(this.props.categories.isFetching){
            return (
                <Layout>
                    <LoadingScreen reason={"Načítám kategorie..."}/>
                </Layout>
                )
        }
        return (
            <Layout>
                <Paper className={this.props.classes.paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Kategorie</TableCell>
                                <TableCell>Barva</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        {this.props.categories.data.map(category => {
                            return (
                                <CategoryRow category={category} />
                            );
                            }
                        )}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">
                                <Button variant="contained" size="small" color="primary" onClick={e => this.createEvent(e)}>
                                    Přidat
                                </Button>
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Dialog open={this.state.open} onClose={this.handleClose}>
                        <AddCategoryModal handleClose={this.handleClose}/>
                    </Dialog>
                </Paper>
            </Layout>
        );
    }

    createEvent = (e) => {
        e.preventDefault();

        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    }

}

function mapStateToProps(state) {
    const categories = state.categoryList;

    return {
        categories,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Events))
import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add"
import {addCartProduct} from "../actions/cartActions";
import {connect} from "react-redux";

class Product extends React.Component {

    classes = makeStyles(theme => ({
        button: {
            margin: theme.spacing(1),
        },
        input: {
            display: 'none',
        }
    }));

    render() {
        return (
            <Grid item xs={12} sm={6} md={4}>
                <Paper>
                    <Grid container style={{
                        margin: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        padding: '0px'
                    }}>
                        <Grid item xs={3} style={{padding: '0px'}}>
                            <img alt={this.props.name + "-picture"} src={"/api/products/images/" + this.props.id} style={{maxHeight: '100%', maxWidth: '100%'}}/>
                        </Grid>
                        <Grid item xs={5} style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0px'
                        }}>
                            <div style={{textAlign: 'center'}}>{this.props.name}</div>
                            <div style={{fontWeight: '800'}}>
                                {this.props.price[0].amount} {this.props.price[0].currency}
                            </div>
                        </Grid>
                        <Grid item container xs={4} justify="center" alignItems="center">
                            <Button variant="contained" size="small" color="secondary" className={this.classes.button}
                                    onClick={this.addProduct}>
                                <AddIcon /> Přidat
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    }

    addProduct = (e) => {
        e.preventDefault();

        this.props.dispatch(addCartProduct(this.props.id));
    }
}

export default connect()(Product)
import axios from 'axios';
import {logout} from "./actions/accountsActions";


const httpService = {
// we pass the redux store and history in order to dispatch the logout actions
// and push the user to login

    setupInterceptors: (store, history) => {
        axios.interceptors.response.use((response) => {
            // simply return the response if there is no error

            return response;
        }, (error) => {
            // in this case we only care about unauthorized errors

            if (error.response.status === 401) {

                store.dispatch(logout());

                history.push('/login');
            }
            return Promise.reject(error);
        });
    }
};

export default httpService;
import React from "react";
import Paper from "@material-ui/core/Paper";
import {connect} from "react-redux";
import {selectEvent} from "../actions/eventsActions";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    selected: {
        background: 'white',
        fontSize: '24px',
        border: '2px solid #E10050'
    },
    normal: {
        background: '#fafafa',
        fontSize: '24px'
    },
}));

function Event(props) {
    const classes = useStyles();

    return (
        <Grid item container xs={12} alignItems={"center"} justify={"center"}>
            <Grid item xs={12} sm={6} md={6}>
                {console.log(props.selected)}
                <Paper onClick={e => handleClick(e, props)} className={props.selected ? classes.selected : classes.normal}>
                    {props.event.name}
                </Paper>
            </Grid>
        </Grid>
    );

}

function handleClick(e, props) {
    e.preventDefault();

    console.log(props.event.id);
    props.dispatch(selectEvent(props.event.id))
}

export default connect()(Event);
import axios from "axios";
import Cookies from "js-cookie";
import {fetchCartProducts} from "./fetchCart";

export function fetchCashDesk(eventId) {
    return async function (dispatch, state) {
        dispatch({type: 'FETCHING_CASHDESK'});
        axios.get('/api/stats/cashdesk/' + eventId)
            .then(res => {
                dispatch({type: 'FETCH_CASHDESK', payload: res.data})
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function addToCashDesk(eventId, reason, amount) {
    return async function (dispatch, state) {
        axios('/api/stats/cashdesk', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: JSON.stringify({
                eventId: eventId,
                reason: reason,
                amount: amount,
            })
        })
            .then(res => {
                console.log(res.data);
                if (res.data === 'SUCCESS') {
                    dispatch({
                        type: 'ADD_TO_CASHDESK', payload: {
                            amount: amount,
                            reason: reason,
                        }
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
}
import React from 'react';
import {connect, ConnectedProps} from "react-redux";
import Layout from "../components/Layout";
import {fetchCashDesk} from "../actions/cashDeskActions";
import LoadingScreen from "../components/LoadingScreen";
import Grid from "@material-ui/core/Grid";
import EventTotalSales from "../components/stats/EventTotalSales";
import DailyTotalSalesChart from "../components/stats/DailyTotalSalesChart";
import Paper from "@material-ui/core/Paper";
import {createStyles, Divider, Snackbar, Theme, WithStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import UserRow from "../components/UserRow";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import MysteryBox from "../components/MysteryBox";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";
import {fetchAdminProducts, fetchProducts} from "../actions/fetchProducts";
import Product from "../components/Product";
import {RouteComponentProps} from "react-router-dom";
import {Package} from "../types/Packages";
import PackagesAddProductsList from "../components/pkgs/PackagesAddProductsList";
import TableHead from "@material-ui/core/TableHead";
import PackagesAddProductsRow from "../components/pkgs/PackagesAddProductsRow";
import PackageProductsList from "../components/pkgs/PackageProductsList";
import { Fragment } from 'react';

const styles = (theme: Theme) => createStyles({
    paper: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
    paper2: {
        width: '1',
        padding: theme.spacing(3, 2),
        paddingTop: theme.spacing(1)
    },
    header: {
        marginBottom: theme.spacing(0.5),
    },
    total: {
        textAlign: "center",
        padding: theme.spacing(1),
    },
    generateButton: {
        margin: theme.spacing(1),
    },
    completeButton: {
        margin: theme.spacing(1),
        backgroundColor: '#f9a825',
        color: 'white',
        '&:hover': {
            backgroundColor: '#f57f17',
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: '#f9a825',
            },
        },
    },
    discardButton: {
        margin: theme.spacing(1),
    }
});

type PropsFromRedux = ConnectedProps<typeof connector>

export interface PackageSiteProps extends WithStyles<typeof styles>, PropsFromRedux, RouteComponentProps<{packageId?: string}> {

}

export interface PackageSiteState {
    isFetching: boolean,
    package: Package,
    error: string | null
    alert: string | null
    alertSeverity: 'success' | 'info' | 'warning' | 'error',
    formValues: Map<number, string>
}

class PackageSite extends React.Component<PackageSiteProps, PackageSiteState> {

    constructor(props: PackageSiteProps) {
        super(props);
        
        this.state = {isFetching: true, package: null, error: null, alert: null, alertSeverity: "success", formValues: new Map()}
    }

    componentDidMount() {
        if(!this.props.match.params.packageId){
            this.setState({isFetching: false, error: "Invalid package id"});
            return;
        }
        axios.get(`/api/packages/${this.props.match.params.packageId}`)
            .then(res => {
                console.log(res.data);
                if(res.data === undefined || res.data == null){
                    this.setState({isFetching: false, error: "Nastala chyba při získávání balíčku."});
                    return;
                }
                this.setState({isFetching: false, package: res.data});
            })
            .catch(error => {
                console.log(error)
                this.setState({isFetching: false, error: "Nastala neznámá chyba."})
            });
    }

    render() {
        if(this.state.isFetching){
            return (
                <Layout>
                    <LoadingScreen/>
                </Layout>
            )
        }
        
        if(this.state.error != null){
            return (
                <Layout>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Alert variant="filled" severity="error">
                                <AlertTitle>Chyba</AlertTitle>
                                {this.state.error}
                            </Alert>
                        </Grid>
                    </Grid>
                </Layout>
            )
        }
        
        return (
            <Layout>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Paper className={this.props.classes.paper2}>
                            <Grid container spacing={1} justify="space-between" alignItems={"center"}>
                                <Grid item>
                                    <h3>Balíček #{this.state.package.id}</h3>
                                </Grid>
                                {
                                    this.state.package.sendDate == null ? <Grid item>
                                        <Button variant="contained" size="small" color="secondary" onClick={() => this.sendPackage()}>
                                            Expedovat
                                        </Button>
                                    </Grid> : <Fragment/>
                                }
                                {
                                    this.state.package.sendDate != null && this.state.package.deliveryDate == null ? <Grid item>
                                        <Button variant="contained" size="small" color="secondary" onClick={() => this.deliveredPackage()}>
                                            Doručeno
                                        </Button>
                                    </Grid> : <Fragment/>
                                }
                            </Grid>
                            <p>Odesláno: {this.state.package.sendDate != null ? new Date(this.state.package.sendDate).toLocaleString() : "--"}</p>
                            <p>Doručeno: {this.state.package.deliveryDate != null ? new Date(this.state.package.deliveryDate).toLocaleString() : "--"}</p>

                            <PackageProductsList package={this.state.package} setAmount={this.setAmount} send={this.state.package.sendDate != null} setTempValue={this.setTempValue}/>
                        </Paper>
                    </Grid>
                    {
                        this.state.package.sendDate == null ? <Grid item xs={12} md={6}>
                            <Paper className={this.props.classes.paper2}>
                                <PackagesAddProductsList eventId={this.state.package.eventId} addProduct={this.addProduct}/>
                            </Paper>
                        </Grid> : <Fragment/>
                    }
                </Grid>
                <Snackbar open={this.state.alert != null} autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.alertSeverity}>
                        {this.state.alert}
                    </Alert>
                </Snackbar>
            </Layout>
        );
    }
    
    handleClose = () => {
        this.setState({alert: null})
    }

    handleChange = (e) => {
        // @ts-ignore
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    
    addProduct = (productId: number) => {
        if(this.state.package.products.find(pr => pr.productId == productId) == null){
            this.setAmount(productId, 1)
        }
    }
    
    setTempValue = (productId: number, amount: string) => {
        this.state.formValues.set(productId, amount);
    }
    
    saveTempValues = async () => {
        // @ts-ignore
        for(let [productId, value] of this.state.formValues.entries()){
            const pr = this.state.package.products.find(pr => pr.productId == productId);
            if(pr.amount != Number(value)){
                await this.setAmountAsync(productId, Number(value));
            }
        }
        this.setState({formValues: new Map()})
    }
    
    setAmountAsync = async (productId: number, amount: number) => {
        try{
            const res = await axios(`/api/packages/${this.state.package.id}/products`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                },
                data: {
                    "productId": productId,
                    "amount": amount
                }
            })
            console.log(res.data);
            if(res.data === undefined || res.data == null){
                this.setState({alert: "Nastala chyba při změně stavu balíčku.", alertSeverity: "error"});
                return;
            }
            const pckg : Package = {... this.state.package};
            pckg.products = res.data
            this.setState({package: pckg, alert: "Uloženo", alertSeverity: "success"});
        }catch (error) {
            console.log(error)
            this.setState({alert: "Nastala neznámá chyba.", alertSeverity: "error"}) 
        }
        
    }
    
    setAmount = (productId: number, amount: number) => {
        this.setAmountAsync(productId, amount);
    }
    
    sendPackage = async () => {
        await this.saveTempValues();
        axios(`/api/packages/${this.state.package.id}/send`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            data: {}
        }).then(res => {
            console.log(res.data);
            if(res.data === undefined || res.data == null){
                this.setState({alert: "Nastala chyba při změně stavu balíčku.", alertSeverity: "error"});
                return;
            }
            const pckg : Package = res.data;
            pckg.products = this.state.package.products
            this.setState({package: pckg, alert: "Balíček byl úspěšně vyexpedován.", alertSeverity: "success"});
        })
            .catch(error => {
                console.log(error)
                this.setState({alert: "Nastala neznámá chyba.", alertSeverity: "error"})
            });
    }

    deliveredPackage = () => {
        axios(`/api/packages/${this.state.package.id}/delivered`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            data: {}
        }).then(res => {
            console.log(res.data);
            if(res.data === undefined || res.data == null){
                this.setState({alert: "Nastala chyba při změně stavu balíčku."});
                return;
            }
            const pckg : Package = res.data;
            pckg.products = this.state.package.products
            this.setState({package: pckg, alert: "Balíček byl úspěšně přijat."});
        })
            .catch(error => {
                console.log(error)
                this.setState({alert: "Nastala neznámá chyba."})
            });
    }

}

const mapState = (state) => ({
    user: state.loggedAs
})

const mapDispatch = {

}

const connector = connect(mapState, mapDispatch)

export default connector(withStyles(styles)(PackageSite))
import React from 'react';
import {connect} from "react-redux";
import Layout from "../components/Layout";
import {fetchCashDesk} from "../actions/cashDeskActions";
import LoadingScreen from "../components/LoadingScreen";
import Grid from "@material-ui/core/Grid";
import EventTotalSales from "../components/stats/EventTotalSales";
import DailyTotalSalesChart from "../components/stats/DailyTotalSalesChart";
import Paper from "@material-ui/core/Paper";
import {Divider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import UserRow from "../components/UserRow";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import MysteryBox from "../components/MysteryBox";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";
import {fetchAdminProducts, fetchProducts} from "../actions/fetchProducts";
import Product from "../components/Product";

const styles = theme => ({
    paper: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
    paper2: {
        width: '1',
        padding: theme.spacing(3, 2),
        paddingTop: theme.spacing(1)
    },
    header: {
        marginBottom: theme.spacing(0.5),
    },
    total: {
        textAlign: "center",
        padding: theme.spacing(1),
    },
    generateButton: {
        margin: theme.spacing(1),
    },
    completeButton: {
        margin: theme.spacing(1),
        backgroundColor: '#f9a825',
        color: 'white',
        '&:hover': {
            backgroundColor: '#f57f17',
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: '#f9a825',
            },
        },
    },
    discardButton: {
        margin: theme.spacing(1),
    }
});

class MysteryBoxGenerator extends React.Component {

    constructor(props) {
        super(props);

        let eventId = 0;
        if (this.props.user != null) {
            eventId = this.props.match.params.eventId != null ? this.props.match.params.eventId : this.props.user.eventId;
        }
        let mysteryBox = this.props.products.data.find(product => product.mystery);
        this.state = {eventId: eventId, isFetching: true, setId: -1 ,boxes: [], productId: mysteryBox ? mysteryBox.id : 0, amount: 1, premiumNumber: 0, premiumPriceLimit: 450, error: null, completed: false};
    }

    componentDidMount() {
        this.props.dispatch(fetchProducts());

        axios.get(`/api/mysterybox/getcurrentset`)
            .then(res => {
                console.log(res.data);
                if(res.data === undefined || res.data == null || res.data.boxes === undefined){
                    this.setState({isFetching: false});
                    return;
                }
                this.setState({isFetching: false,setId: res.data.setId, boxes: res.data.boxes});
            })
            .catch(error => console.log(error));
    }

    render() {
        return (
            <Layout>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Paper className={this.props.classes.paper2}>
                            <h3>Mystery Boxy</h3>
                            <Grid container spacing={0}>
                                <Grid item md={4} xs={12}>
                                    <TextField style={{margin: '10px'}}
                                               id="outlined-amount"
                                               label="Počet"
                                               value={this.state.amount}
                                               onChange={e => this.handleChange(e)}
                                               margin="normal"
                                               variant="outlined"
                                               name="amount"
                                               type={"number"}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField style={{margin: '10px'}}
                                               id="outlined-select-mysterybox"
                                               select
                                               label="Typ"
                                               value={this.state.productId}
                                               onChange={e => this.handleChange(e)}
                                               name={"productId"}
                                               SelectProps={{
                                                   native: true,
                                                   MenuProps: {
                                                       className: this.props.classes.selectMenu,
                                                   },
                                               }}
                                               margin="normal"
                                               variant="outlined"
                                    >
                                        {this.props.products.data.filter(product => product.mystery).map(product => (
                                            <option key={product.name.toLowerCase()} value={product.id}>
                                                {product.name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Button variant="contained" size="small" color="primary" className={this.props.classes.generateButton}
                                            onClick={e => this.generate(e)}>
                                        Vygenerovat sadu
                                    </Button>
                                    {
                                        this.completebutton()
                                    }
                                    {
                                        this.discardbutton()
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    {
                    this.boxes()
                    }
                </Grid>
                
            </Layout>
        );
    }
    
    completebutton = () => {
        if(this.state.setId != -1){
            return (
                <Button variant="contained" size="small" className={this.props.classes.completeButton}
                        onClick={e => this.completeset(e)}>
                    Dokončit sadu
                </Button>
            );
        }
    }

    discardbutton = () => {
        if(this.state.setId != -1){
            return (
                <Button variant="contained" size="small" color="secondary" className={this.props.classes.discardButton}
                        onClick={e => this.discardset(e)}>
                    Zahodit sadu
                </Button>
            );
        }
    }

    boxes = () => {
        if(this.state.error != null){
            return (
                <Grid item xs={12}>
                    <Alert variant={"filled"} severity="error">
                        <AlertTitle>Chyba</AlertTitle>
                        {this.state.error}
                    </Alert>
                </Grid>
            );
        }
        if (this.state.isFetching) {
            return (
                <LoadingScreen />
            );
        }
        var number = 0;
        return (
            this.state.boxes.map(box => {
                number++;
                return (
                    <Grid item xs={12} sm={12} md={6}>
                        <MysteryBox box={box} number={number}/>
                    </Grid>
                    
                );
                
            })
        );
    }

    generate = (e) => {
        e.preventDefault();
        
        const productId = this.state.productId;
        const amount = this.state.amount;
        const premiumNumber = this.state.premiumNumber;
        const premiumPriceLimit = this.state.premiumPriceLimit;

        this.setState({isFetching: true, error: null});
        axios.get(`/api/mysterybox/generateset?productId=${productId}&amount=${amount}&premiumNumber=${premiumNumber}&premiumPriceLimit=${premiumPriceLimit}`)
            .then(res => {
                console.log(res.data);
                if(res.data === undefined || res.data == null || res.data.boxes === undefined){
                    this.setState({isFetching: false, error: "Nebyla nalezena vhodná kombinace pro složení těchto Mystery boxů."})
                    return;
                }
                this.setState({isFetching: false,setId: res.data.setId, boxes: res.data.boxes});
            })
            .catch(error => console.log(error));
    }
    
    completeset = (e) => {
        e.preventDefault();
        
        const eventId = this.state.eventId;
        const setId = this.state.setId;
        
        axios.get(`/api/mysterybox/completeset?eventId=${eventId}&setId=${setId}`)
            .then(res => {
                console.log(res.data);
                this.setState({setId: -1, boxes: []});
            })
            .catch(error => console.log(error));
    }

    discardset = (e) => {
        e.preventDefault();

        const eventId = this.state.eventId;
        const setId = this.state.setId;

        axios.get(`/api/mysterybox/discardset?eventId=${eventId}&setId=${setId}`)
            .then(res => {
                console.log(res.data);
                this.setState({setId: -1, boxes: []});
            })
            .catch(error => console.log(error));
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleClose = () => {
        this.setState({open: false})
    }
    
}

function mapStateToProps(state) {
    const user = state.loggedAs;
    const products = state.productList;
    return {
        user,
        products
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MysteryBoxGenerator))
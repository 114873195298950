import {Component} from "react";
import React from "react";
import {connect, ConnectedProps} from "react-redux";
import Layout from "../../components/Layout";
import {fetchStock} from "../../actions/stockActions";
import {fetchEvents} from "../../actions/eventsActions";
import LoadingScreen from "../../components/LoadingScreen";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import {fetchProducts} from "../../actions/fetchProducts";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import withStyles from "@material-ui/core/styles/withStyles";
import {createStyles, TextField, Theme, WithStyles} from "@material-ui/core";
import {RouteComponentProps} from "react-router-dom";
import {Package} from "../../types/Packages";
import PackagesAddProductsRow from "./PackagesAddProductsRow";
import { Fragment } from "react";

const styles = (theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(3, 2),
    },
});

type PropsFromRedux = ConnectedProps<typeof connector>

export interface PackagesAddProductsListProps extends WithStyles<typeof styles>, PropsFromRedux {
    eventId: number,
    addProduct: (productId: number) => void
}

export interface PackagesAddProductsListState {
    search: string
}

class PackagesAddProductsList extends Component<PackagesAddProductsListProps,PackagesAddProductsListState> {

    constructor(props: PackagesAddProductsListProps){
        super(props);
        
        this.state = {search: ""}
    }

    componentDidMount() {
        this.props.fetchProducts();
        this.props.fetchEvents();
        this.props.fetchStock(this.props.eventId)
    }

    render(){
        if(this.props.stock.isFetching || this.props.events.isFetching || this.props.products.isFetching){
            return (
                <LoadingScreen reason={"Načítám skladové údaje"}/>
            );
        }
        return (
                    <Fragment>
                        <Typography variant={"h4"}>
                            {
                                this.props.events.data.map(e => {
                                    if(e.id === this.props.eventId){
                                        return e.name;
                                    }
                                })}
                        </Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Produkt</TableCell>
                                    <TableCell align="right">Množství</TableCell>
                                    <TableCell align="right">
                                        <TextField style={{margin: '10px'}}
                                                   id="outlined-search"
                                                   label="Vyhledat"
                                                   value={this.state.search}
                                                   onChange={e => this.handleChange(e)}
                                                   margin="normal"
                                                   variant="outlined"
                                                   name="search"
                                                   type="search"
                                                   size={"small"}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                this.props.products.data.filter(p => {
                                    if(this.state.search == ""){
                                        return true;
                                    }
                                    return p.name.includes(this.state.search);
                                }).map(product => {

                                    var amount = 0;
                                    this.props.stock.data.forEach(p => {
                                        if(product.id === p.productId){
                                            amount = p.amount
                                        }
                                    });

                                    return <PackagesAddProductsRow productName={product.name} productId={product.id} amount={amount} addProduct={this.props.addProduct} />;
                                })
                            }
                        </Table>
                    </Fragment>
        );
    }

    
    handleChange = (e) => {
        // @ts-ignore
        this.setState({
            [e.target.name]: e.target.value
        });
    }
}

const mapState = (state) => ({
    user: state.loggedAs,
    events: state.eventList,
    products: state.productList,
    stock: state.stock
})

const mapDispatch = (dispatch) => {
    return {
        fetchProducts: () => dispatch(fetchProducts()),
        fetchEvents: () => dispatch(fetchEvents()),
        fetchStock: (eventId: number) => dispatch(fetchStock(eventId))
    }
}

const connector = connect(mapState, mapDispatch)

export default connector(withStyles(styles)(PackagesAddProductsList));
import Cookies from 'js-cookie'

export function loggedInReducer(logedIn = false, action) {
    if (action.type === 'ACCOUNT_LOGGED_IN') {
        return true;
    }
    if (action.type === 'ACCOUNT_LOGOUT') {
        return false;
    }
    return logedIn;
}

export function loggingInReducer(logedIn = false, action) {
    if (action.type === 'ACCOUNT_LOGGING_IN') {
        return true;
    }
    if (action.type === 'ACCOUNT_LOGGED_IN') {
        return false;
    }
    if (action.type === 'ACCOUNT_LOGIN_FAILED') {
        return false;
    }
    if (action.type === 'ACCOUNT_LOGIN_ERROR') {
        return false;
    }

    return logedIn;
}

export function loginTokenReducer(token = null, action) {
    if (token == null && Cookies.get("loginToken") != null) {
        token = Cookies.get("loginToken");
    }
    if (action.type === 'ACCOUNT_LOGIN_FAILED') {
        if (action.payload.reason === 'INVALID_TOKEN') {
            Cookies.remove("loginToken");
            return null;
        }
    }
    if (action.type === 'ACCOUNT_LOGGED_IN') {
        Cookies.set("loginToken", action.payload.token, { expires: 30 });
        return action.payload.token;
    }
    if (action.type === 'ACCOUNT_LOGOUT') {
        Cookies.remove("loginToken");
        return null;
    }
    return token;
}

export function loggedAsReducer(user = null, action) {
    if (action.type === 'ACCOUNT_LOGGED_IN') {
        return action.payload.user;
    }
    if (action.type === 'SELECT_EVENT') {
        if(user != null){
            return Object.assign({}, user, {
                eventId: action.payload
            });
        }
    }
    if (action.type === 'ACCOUNT_LOGOUT') {
        return null;
    }
    return user;
}
import React from "react";
import Typography from "@material-ui/core/Typography";
import {Divider} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/styles/withStyles";
import Layout from "./Layout";
import LoadingScreen from "./LoadingScreen";


const styles = theme => ({
    paper: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
    header: {
        marginBottom: theme.spacing(0.5),
    },
    total: {
        textAlign: "center",
        padding: theme.spacing(1),
    },
});

class CashDeskTotal extends React.Component {

    render() {
        if (this.props.cashDesk.isFetching) {
            return (
                <Paper className={this.props.classes.paper}>
                    <LoadingScreen reason={"Stahuji data"} />
                </Paper>
            );
        }
        return (
            <Paper className={this.props.classes.paper}>
                <Typography variant={"h6"} className={this.props.classes.header}>
                    Hotovost v pokladně
                </Typography>
                <Divider/>
                <Typography variant={"h2"} component={"div"} className={this.props.classes.total}>
                    {formatNumber(this.props.cashDesk.amount)} Kč
                </Typography>
            </Paper>
        )
    };

}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

function mapStateToProps(state) {
    const cashDesk = state.cashDesk;
    return {
        cashDesk,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CashDeskTotal));
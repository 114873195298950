import {Component} from "react";
import {Divider, TableBody, TableHead, Theme, WithStyles} from "@material-ui/core";
import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import {connect, DefaultRootState} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import LoadingScreen from "../LoadingScreen";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import {fetchEvents} from "../../actions/eventsActions";
import AddProductModal from "../modals/AddProductModal";
import Dialog from "@material-ui/core/Dialog";
import AddEventToUserModal from "../modals/AddEventToUserModal";
import {addEventToUser, removeEventToUser} from "../../actions/accountsActions";
import PackageRow from "./PackageRow";
import {Package} from "../../types/Packages";
import { Fragment } from "react";

const styles = (theme : Theme) => ({
    eventList: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
});

export interface CustomButton {
    displayName: string,
    action: () => void
}

export interface PackagesListProps extends WithStyles<typeof styles>{
    button?: CustomButton | undefined | null
    packages: Package[],
    displayName: string
}

export interface PackagesListState {

}

class PackagesList extends Component<PackagesListProps, PackagesListState> {

    constructor(props: PackagesListProps) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <Paper className={this.props.classes.eventList}>
                <Typography variant={"h6"}>
                    {this.props.displayName}
                </Typography>
                <Divider/>
                <Table>
                   <TableHead>
                       <TableRow>
                           <TableCell>#</TableCell>
                           <TableCell>Odesláno</TableCell>
                           <TableCell>Doručeno</TableCell>
                           <TableCell align="right">
                               {
                                   this.props.button ? <Button variant="contained" size="small" color="secondary" onClick={this.buttonAction}>
                                       {this.props.button.displayName}
                                   </Button> : <Fragment/>
                               }
                           </TableCell>
                       </TableRow>
                   </TableHead> 
                    
                    <TableBody>
                        {this.props.packages.map(pcg => {
                            return (
                                <PackageRow package={pcg}/>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
    
    buttonAction = (e) => {
        e.preventDefault();

        this.props.button.action();
    }

}

function mapStateToProps(state: DefaultRootState) {

}

export default connect(mapStateToProps)(withStyles(styles)(PackagesList))
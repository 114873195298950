import React, {Component} from "react";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import {addProduct} from "../../actions/productsActions";
import {createEvent} from "../../actions/eventsActions";
import {createUserAccount} from "../../actions/accountsActions";

const styles = theme => ({
    modalGrid: {
        padding: theme.spacing(3, 2),
    },
    selectMenu: {
        width: '200px',
    },
});

class AddUserModal extends Component {

    constructor(props) {
        super(props);

        console.log(props.events);
        this.state = {userName: "", password: "", name: "", surname: "", groupId: 1, eventId: props.events.data[0].id}
    }

    render() {
        return (
            <Grid container direction={"column"} className={this.props.classes.modalGrid}>
                <Typography variant={"h4"}>
                    Přidat uživatele
                </Typography>
                <Divider/>
                <TextField style={{margin: '10px'}}
                           id="outlined-username"
                           label="Uživatelské jméno"
                           value={this.state.userName}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="userName"
                />
                <TextField style={{margin: '10px'}}
                           id="outlined-password"
                           label="Heslo"
                           value={this.state.password}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="password"
                           type="password"
                />
                <TextField style={{margin: '10px'}}
                           id="outlined-name"
                           label="Jméno"
                           value={this.state.name}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="name"
                />
                <TextField style={{margin: '10px'}}
                           id="outlined-surname"
                           label="Příjmení"
                           value={this.state.surname}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="surname"
                />
                <TextField style={{margin: '10px'}}
                           id="outlined-select-group"
                           select
                           label="Skupina"
                           value={this.state.groupId}
                           onChange={e => this.handleChange(e)}
                           name={"groupId"}
                           SelectProps={{
                               native: true,
                               MenuProps: {
                                   className: this.props.classes.selectMenu,
                               },
                           }}
                           margin="normal"
                           variant="outlined"
                >
                    <option key="1" value="1">
                        Prodejce
                    </option>
                    <option key="10" value="10">
                        Administrátor
                    </option>
                </TextField>
                <TextField style={{margin: '10px'}}
                           id="outlined-select-event"
                           select
                           label="Akce"
                           value={this.state.eventId}
                           onChange={e => this.handleChange(e)}
                           name={"eventId"}
                           SelectProps={{
                               native: true,
                               MenuProps: {
                                   className: this.props.classes.selectMenu,
                               },
                           }}
                           margin="normal"
                           variant="outlined"
                >
                    {this.props.events.data.map(option => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </TextField>
                <Button id={"modalButton"} variant="contained" size="medium" color="primary"
                        onClick={e => this.save(e)}>
                    Přidat uživatele
                </Button>
            </Grid>
        );
    }

    save = (e) => {
        e.preventDefault();

        this.props.dispatch(createUserAccount(this.state.userName, this.state.password, this.state.name, this.state.surname, this.state.groupId, this.state.eventId));
        this.props.handleClose();
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

}

function mapStateToProps(state) {
    const events = state.eventList;
    return {
        events,
    }
}


export default connect(mapStateToProps)(withStyles(styles)(AddUserModal));
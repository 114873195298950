import axios from "axios";

function shouldFetch(state) {
    if (state().categoryList.isFetching) {
        return true;
    }
    return false;
}


export function fetchCategories() {
    return async function (dispatch, state) {
        if (shouldFetch(state)) {
            axios.get('/api/categories')
                .then(res => {
                    console.log(res.data);
                    dispatch({type: 'FETCH_CATEGORIES', payload: {categories: res.data}})
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
import {Component} from "react";
import Layout from "../components/Layout";
import React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import Table from "@material-ui/core/Table";
import UserRow from "../components/UserRow";
import LoadingScreen from "../components/LoadingScreen";
import {fetchUsers} from "../actions/fetchUsers";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import {createUserAccount} from "../actions/accountsActions";
import AddCategoryModal from "../components/modals/AddCategoryModal";
import Dialog from "@material-ui/core/Dialog";
import AddUserModal from "../components/modals/AddUserModal";
import {fetchEvents} from "../actions/eventsActions";

const styles = {
    paper: {
        width: '1',
    },
};

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {open: false}
    }

    componentDidMount() {
        this.props.dispatch(fetchUsers());
        this.props.dispatch(fetchEvents());
    }

    render() {
        if (this.props.users.isFetching || this.props.events.isFetching) {
            return (
                <Layout>
                    <LoadingScreen reason={"Načítám uživatele..."}/>
                </Layout>
            )
        }
        return (
            <Layout>
                <Paper className={this.props.classes.paper}>
                    <Table>
                        {this.props.users.data.map(user => {
                                return (
                                    <UserRow user={user}/>
                                );
                            }
                        )}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">
                                <Button variant="contained" size="small" color="primary"
                                        onClick={e => this.createUser(e)}>
                                    Přidat
                                </Button>
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Dialog open={this.state.open} onClose={this.handleClose}>
                        <AddUserModal handleClose={this.handleClose} events={this.props.events}/>
                    </Dialog>
                </Paper>
            </Layout>
        );
    }

    createUser = (e) => {
        e.preventDefault();

        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    }

}

function mapStateToProps(state) {
    const users = state.userList;
    const events = state.eventList;

    return {
        users,
        events
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Users))
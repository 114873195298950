import axios from "axios";

export function fetchEventStats(eventId) {
    return async function (dispatch, state) {
        axios.get('/api/stats/' + eventId)
            .then(res => {
                dispatch({type: 'FETCH_EVENT_STATS', payload: {stats: res.data}})
            })
            .catch(error => {
                console.log(error);
            });
    }
}
import {Component} from "react";
import React from "react";
import {connect, ConnectedProps} from "react-redux";
import Layout from "../../components/Layout";
import {fetchStock} from "../../actions/stockActions";
import {fetchEvents} from "../../actions/eventsActions";
import LoadingScreen from "../../components/LoadingScreen";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import {fetchProducts} from "../../actions/fetchProducts";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import withStyles from "@material-ui/core/styles/withStyles";
import {createStyles, TextField, Theme, WithStyles} from "@material-ui/core";
import {RouteComponentProps} from "react-router-dom";
import {Package} from "../../types/Packages";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ProductStockRowModal from "../modals/ProductStockRowModal";
import {addProduct} from "../../actions/productsActions";

const styles = (theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(3, 2),
    },
});

export interface PackagesAddProductsRowProps extends WithStyles<typeof styles> {
    productName: string,
    productId: number,
    amount: number,
    addProduct: (productId: number) => void
}


export interface PackagesAddProductsRowState {

}

class PackagesAddProductsRow extends Component<PackagesAddProductsRowProps,PackagesAddProductsRowState> {

    constructor(props: PackagesAddProductsRowProps){
        super(props);
    }

    render(){
        return (
            <TableRow>
                <TableCell>
                    {this.props.productName}
                </TableCell>
                <TableCell align={"right"}>
                    {this.props.amount} ks
                </TableCell>
                <TableCell align={"right"}>
                    <Button variant="contained" size="small" color="secondary" onClick={() => this.props.addProduct(this.props.productId)}>
                        +
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
    
}

export default withStyles(styles)(PackagesAddProductsRow);
import React, {Component} from "react";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import {addProduct} from "../../actions/productsActions";
import {createEvent} from "../../actions/eventsActions";
import {addCategory, editCategory} from "../../actions/categoriesActions";
import {ColorPicker} from "material-ui-color";

const styles = theme => ({
    modalGrid: {
        padding: theme.spacing(3, 2),
    },
    selectMenu: {
        width: '200px',
    },
});

class EditCategoryModal extends Component {

    constructor(props) {
        super(props);

        this.state = {name: this.props.category.name, color: this.props.category.color}
    }

    render() {
        return (
            <Grid container direction={"column"} className={this.props.classes.modalGrid}>
                <Typography variant={"h4"}>
                    Upravit kategorii
                </Typography>
                <Divider/>
                <TextField style={{margin: '10px'}}
                           id="outlined-name"
                           label="Jméno kategorie"
                           value={this.state.name}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="name"
                />
                <ColorPicker disableAlpha disablePlainColor palette={{red: '#ff0000', lighblue: '#00BFFF'}}
                    value={this.state.color}
                    onChange={color => this.handleColorChange(color)}
                />
                <Button id={"modalButton"} variant="contained" size="medium" color="secondary"
                        onClick={e => this.save(e)}>
                    Uložit změny
                </Button>
            </Grid>
        );
    }

    save = (e) => {
        e.preventDefault();

        this.props.dispatch(editCategory(this.props.category.id, this.state.name, this.state.color));
        this.props.handleClose();
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    handleColorChange = (color) => {
        this.setState({
            color: "#" + color.hex
        })
    }

}


export default connect()(withStyles(styles)(EditCategoryModal));
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

export default function LoadingScreen(props) {

    return (
        <Grid container justify={"center"} alignItems={"center"} direction={"column"}
              style={{
                  height: '100%',
                  width: '100%',
              }}
        >
            <CircularProgress style={{marginBottom: '10px', marginTop: '20px'}}/>
            <div style={{
                color: '#2196f3',
                fontWeight: '500',
            }}>
                {props.reason == null ? "Loading" : props.reason}
            </div>
        </Grid>
    );
}
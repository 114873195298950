import React, {Component} from 'react';
import {Typography, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import LoadingScreen from "../LoadingScreen";
import {fetchProducts} from "../../actions/fetchProducts";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Moment from "react-moment";
import axios from "axios";
import CardIcon from "@material-ui/icons/CreditCard"
import MoneyIcon from "@material-ui/icons/MonetizationOn"
import Button from "@material-ui/core/Button";
import {resetReceipt} from "../../actions/receiptsActions";

const styles = theme => ({
    modal: {
        padding: theme.spacing(2, 1),
    },
    products: {
        padding: theme.spacing(1),
        paddingBottom: 0,
    },
});

class ReceiptModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            receipt: {
                isFetching: true,
                data: {}
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(fetchProducts());

        axios.get('/api/receipts/details/' + this.props.receiptId)
            .then(res => {
                this.setState({
                    receipt: {
                        isFetching: false,
                        data: res.data,
                    }
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        if (this.state.receipt.isFetching || this.props.products.isFetching) {
            return (
                <div className={this.props.classes.modal}>
                    <Typography variant={"h4"}>
                        Doklad #{this.props.receiptId}
                    </Typography>
                    <Divider/>
                    <LoadingScreen reason={"Načítám doklad"}/>
                </div>
            );
        }
        return (
            <div className={this.props.classes.modal}>
                <Grid container direction={"row"} justify={"space-between"}>
                    <Typography variant={"h4"}>
                        Doklad #{this.props.receiptId}
                    </Typography>
                    <Typography variant={"h4"}>
                        {this.state.receipt.data.payType === "CASH" ?
                            <MoneyIcon style={{color: '#00cc00'}}/> :
                            <CardIcon/>
                        }
                    </Typography>
                </Grid>
                <Divider/>
                <Grid container direction={"column"}>
                    <Moment style={{fontWeight: '500'}} date={new Date(this.state.receipt.data.date)}
                            format="D. M. YYYY HH:mm"/>
                    <div style={{fontWeight: '500'}}>
                        {this.state.receipt.data.seller}
                    </div>
                </Grid>

                {this.state.receipt.data.products.map(product => {
                    var name = "Not found";
                    this.props.products.data.forEach(p => {
                        if (p.id === product.productId) {
                            name = p.name;
                        }
                    });
                    return (
                        <Grid container direction={"row"} justify={"space-between"} className={this.props.classes.products}>
                            <div style={{fontWeight: '500'}}>{product.amount}x {name}</div>
                            <div>{product.price} Kč</div>
                        </Grid>
                    );
                })}
                <Divider/>
                <Grid container direction={"row"} justify={"space-between"} className={this.props.classes.products}>
                    <div>Celková cena:</div>
                    <div style={{fontWeight: '500'}}>{this.state.receipt.data.price} Kč</div>
                </Grid>
                <Grid container direction={"row"} justify={"flex-end"} className={this.props.classes.products}>
                    <Button variant="contained" size="small" color="secondary" onClick={this.handleReset}>
                        Stornovat
                    </Button>
                </Grid>

            </div>
        );
    }

    handleReset = (e) => {
        e.preventDefault();

        this.props.dispatch(resetReceipt(this.props.receiptId));
        this.props.handleClose();
    }

}

function mapStateToProps(state) {
    const products = state.productList;
    return {
        products,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(ReceiptModal));
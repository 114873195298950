import axios from "axios";

function shouldFetch(state) {
    if (state().productList.isFetching) {
        return true;
    }
    return false;
}


export function fetchProducts() {
    return async function (dispatch, state) {
        if (shouldFetch(state)) {
            axios.get('/api/products')
                .then(res => {
                    console.log(res.data);
                    dispatch({type: 'FETCH_PRODUCTS', payload: {products: res.data}})
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

export function fetchAdminProducts() {
    return async function (dispatch, state) {
        dispatch({type: 'START_FETCHING_PRODUCTS'})
            axios.get('/api/products/all')
                .then(res => {
                    console.log(res.data);
                    dispatch({type: 'FETCH_PRODUCTS', payload: {products: res.data}})
                })
                .catch(error => {
                    console.log(error);
                });
    }
}
import {Component} from "react";
import React from "react";
import Moment from "react-moment"
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CardIcon from "@material-ui/icons/CreditCard"
import MoneyIcon from "@material-ui/icons/MonetizationOn"
import ReceiptModal from "./modals/ReceiptModal";


class ReceiptInfo extends Component {

    constructor(props){
        super(props);

        this.state = {open: false}
    }

    render(){
        return (
            <TableRow style={{backgroundColor: (this.props.receipt.valid == 1 ? "#FFFFFF" : "#E57373")}}>
                <TableCell>#{this.props.receipt.id}</TableCell>
                <TableCell><Moment date={new Date(this.props.receipt.date)} format="D. M. YYYY HH:mm"/></TableCell>
                <TableCell align="right">{this.props.receipt.price} Kč</TableCell>
                <TableCell align="center">{this.props.receipt.payType === "CASH" ? <MoneyIcon style={{color: '#00cc00'}} /> : <CardIcon />}</TableCell>
                <TableCell align="right">
                    <Button variant="contained" size="small" color="secondary" onClick={this.handleDetails}>
                        Detail
                    </Button>
                </TableCell>
                <Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth={"xs"}>
                    <ReceiptModal receiptId={this.props.receipt.id} handleClose={this.handleClose}/>
                </Dialog>
            </TableRow>
        );
    }

    handleDetails = e => {
        e.preventDefault();

        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

}

export default ReceiptInfo;
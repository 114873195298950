import React, {Component} from "react";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import {addProduct} from "../../actions/productsActions";
import {createEvent} from "../../actions/eventsActions";
import {addEventToUser} from "../../actions/accountsActions";

const styles = theme => ({
    modalGrid: {
        padding: theme.spacing(3, 2),
    },
    selectMenu: {
        width: '200px',
    },
});

class AddEventModal extends Component {

    constructor(props) {
        super(props);

        this.state = {event: props.events[0].name}
    }

    render() {
        return (
            <Grid container direction={"column"} className={this.props.classes.modalGrid}>
                <Typography variant={"h4"}>
                    Přidat akci
                </Typography>
                <Divider/>
                <TextField style={{margin: '10px'}}
                           id="outlined-select-currency"
                           select
                           label="Akce"
                           value={this.state.event}
                           onChange={e => this.handleChange(e)}
                           name={"event"}
                           SelectProps={{
                               native: true,
                               MenuProps: {
                                   className: this.props.classes.selectMenu,
                               },
                           }}
                           margin="normal"
                           variant="outlined"
                >
                    {this.props.events.map(option => (
                        <option key={option.id} value={option.name}>
                            {option.name}
                        </option>
                    ))}
                </TextField>
                <Button id={"modalButton"} variant="contained" size="medium" color="primary"
                        onClick={e => this.save(e)}>
                    Přidat akci
                </Button>
            </Grid>
        );
    }

    save = (e) => {
        e.preventDefault();

        let eventId = 0;
        this.props.events.forEach(c => {
            if (c.name === this.state.event) {
                eventId = c.id;
            }
        });

        this.props.addEvent(eventId);
        this.props.handleClose();
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

}


export default withStyles(styles)(AddEventModal);
import {Component} from "react";
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import history from "../../history";
import {Theme, WithStyles, withStyles} from "@material-ui/core";
import EditProductModal from "./../modals/EditProductModal";
import Dialog from "@material-ui/core/Dialog";
import EditCategoryModal from "../modals/EdiCategoryModal";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import {Package} from "../../types/Packages";

const styles = (theme : Theme) => ({

});

export interface PackageRowProps extends WithStyles<typeof styles> {
    package: Package
}

export interface PackageRowState {
    
}

class PackageRow extends Component<PackageRowProps, PackageRowState> {

    constructor(props: PackageRowProps) {
        super(props);

        this.state = {open: false}
    }

    render() {
        return (
            <TableRow>
                <TableCell>{this.props.package.id}</TableCell>
                <TableCell>{this.props.package.sendDate ? new Date(this.props.package.sendDate).toLocaleString() : "--"}</TableCell>
                <TableCell>{this.props.package.deliveryDate ? new Date(this.props.package.deliveryDate).toLocaleString() : "--"}</TableCell>
                <TableCell align="right">
                    <Button variant="contained" size="small" color="secondary" onClick={this.openPackagePage}>
                        Detail
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
    
    openPackagePage = (event) => {
        event.preventDefault();

        history.push("/packages/detail/" + this.props.package.id);
    }


}

export default withStyles(styles)(PackageRow);
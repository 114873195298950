import React from 'react';
import {connect} from "react-redux";
import Layout from "../components/Layout";
import {fetchCashDesk} from "../actions/cashDeskActions";
import LoadingScreen from "../components/LoadingScreen";
import Grid from "@material-ui/core/Grid";
import EventTotalSales from "../components/stats/EventTotalSales";
import DailyTotalSalesChart from "../components/stats/DailyTotalSalesChart";
import Paper from "@material-ui/core/Paper";
import {Divider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import UserRow from "../components/UserRow";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import AddEventModal from "../components/modals/AddEventModal";
import Dialog from "@material-ui/core/Dialog";
import AddMoneyToCashDesk from "../components/modals/AddMoneyToCashDesk";
import CashDeskTotal from "../components/CashDeskTotal";

const styles = theme => ({
    paper: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
    paper2: {
        width: '1',
        padding: theme.spacing(3, 2),
        paddingTop: theme.spacing(1)
    },
    header: {
        marginBottom: theme.spacing(0.5),
    },
    total: {
        textAlign: "center",
        padding: theme.spacing(1),
    },
});


class CashDesk extends React.Component {

    constructor(props) {
        super(props);

        if (this.props.user != null) {
            const eventId = this.props.match.params.eventId != null ? this.props.match.params.eventId : this.props.user.eventId;
            this.state = {eventId: eventId, open: false}
        } else {
            this.state = {eventId: 0, open: false}
        }

    }

    componentDidMount() {
        if (this.state.eventId > 0) {
            this.props.dispatch(fetchCashDesk(this.state.eventId));
        }
    }

    render() {
        console.log(this.props.cashDesk);
        if (this.props.cashDesk.isFetching) {
            return (
                <Layout>
                    <LoadingScreen reason={"Přepočítávám data"}/>
                </Layout>
            );
        }
        return (
            <Layout>
                <Grid container spacing={1}>
                    <Grid item md={4} sm={6} xs={12}>
                        <CashDeskTotal />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={this.props.classes.paper2}>
                            <Table>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell align="right">
                                        <Button variant="contained" size="small" color="primary"
                                                onClick={e => this.addMoney(e)}>
                                            Přidat
                                        </Button>
                                    </TableCell>
                                    <Dialog open={this.state.open} onClose={this.handleClose}>
                                        <AddMoneyToCashDesk eventId={this.state.eventId} handleClose={this.handleClose}/>
                                    </Dialog>
                                </TableRow>
                                {this.props.cashDesk.data.map(record => {
                                    let color = record.amount > 0 ? '#81C784' : '#E57373';
                                    let date = new Date(record.date);
                                    return (
                                        <TableRow style={{backgroundColor: color}}>
                                            <TableCell>{date.getDate()}.{date.getMonth() + 1} {date.getFullYear()}</TableCell>
                                            <TableCell>{record.reason}</TableCell>
                                            <TableCell>
                                                {formatNumber(record.amount)} Kč
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
            </Layout>
        );
    }

    addMoney = (e) => {
        e.preventDefault();

        this.setState({open: true})

    }

    handleClose = () => {
        this.setState({open: false})

    }

}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

function mapStateToProps(state) {
    const cashDesk = state.cashDesk;
    const user = state.loggedAs;
    return {
        cashDesk,
        user
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CashDesk))
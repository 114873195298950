import {Component} from "react";
import React from "react";
import {connect} from "react-redux";
import Layout from "../components/Layout";
import LoadingScreen from "../components/LoadingScreen";
import {fetchEventStats} from "../actions/fetchEventStats";
import Grid from "@material-ui/core/Grid";
import EventTotalSales from "../components/stats/EventTotalSales";
import DailyTotalSalesChart from "../components/stats/DailyTotalSalesChart";
import {fetchCashDesk} from "../actions/cashDeskActions";
import CashDeskTotal from "../components/CashDeskTotal";


class EventStats extends Component {

    constructor(props){
        super(props);

        let eventId = 0;
        if(props.user !== null){
            eventId = props.match.params.eventId != null ?
                props.match.params.eventId : props.user.eventId;
        }

        this.state = {eventId: eventId}
    }

    componentDidMount() {
        if(this.state.eventId !== 0){
            this.props.dispatch(fetchEventStats(this.state.eventId))
            this.props.dispatch(fetchCashDesk(this.state.eventId))
        }
    }

    render(){
        return (
            <Layout>
                <Grid container spacing={1}>
                    <Grid item md={4} sm={6} xs={12}>
                        <Grid container direction={"column"} spacing={1}>
                            <Grid item>
                                <EventTotalSales eventId={this.state.eventId} />
                            </Grid>
                            <Grid item>
                                <CashDeskTotal />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <DailyTotalSalesChart eventId={this.state.eventId} />
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

function mapStateToProps(state){
    const user = state.loggedAs;
    const stats = state.statsList;
    return {
        stats,
        user,
    };
}

export default connect(mapStateToProps)(EventStats);
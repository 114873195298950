import {Component} from "react";
import {Divider} from "@material-ui/core";
import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import LoadingScreen from "./LoadingScreen";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import {fetchEvents} from "../actions/eventsActions";
import AddProductModal from "./modals/AddProductModal";
import Dialog from "@material-ui/core/Dialog";
import AddEventToUserModal from "./modals/AddEventToUserModal";
import {addEventToUser, removeEventToUser} from "../actions/accountsActions";

const styles = theme => ({
    eventList: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
});

class UserEventsList extends Component {

    constructor(props) {
        super(props);

        this.state = {open: false};
    }

    componentDidMount() {
        this.props.dispatch(fetchEvents())
    }

    render() {
        if (this.props.events.isFetching) {
            return (
                <Paper className={this.props.classes.eventList}>
                    <Typography variant={"h6"}>
                        Seznam akcí
                    </Typography>
                    <Divider/>
                    <LoadingScreen/>
                </Paper>
            );
        }
        return (
            <Paper className={this.props.classes.eventList}>
                <Typography variant={"h6"}>
                    Seznam akcí
                </Typography>
                <Divider/>
                <Table>
                    {this.props.events.data.filter(e => this.props.userEvents.includes(e.id)).map(ev => {
                        return (
                            <TableRow>
                                <TableCell>{ev.name}</TableCell>
                                <TableCell align="right">
                                    <Button variant="contained" size="small" color="secondary"
                                            onClick={e => this.removeEvent(e, ev.id)}>
                                        Odebrat
                                    </Button>
                                </TableCell>
                            </TableRow>
                        );
                    })}

                    {this.addButton()}

                </Table>
                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <AddEventToUserModal events={this.props.events.data.filter(e =>
                        !this.props.userEvents.includes(e.id)
                    )} handleClose={this.handleClose} addEvent={this.addEvent}/>
                </Dialog>
            </Paper>
        );
    }

    addButton = () => {
        if (this.props.events.data.filter(e => !this.props.userEvents.includes(e.id)).length > 0) {
            return (
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                        <Button variant="contained" size="small" color="primary" onClick={e => this.addEventModal(e)}>
                            Přidat
                        </Button>
                    </TableCell>
                </TableRow>
            )
        }
    };

    removeEvent = (e, eventId) => {
        e.preventDefault();

        this.props.dispatch(removeEventToUser(this.props.user.id, eventId));

    };

    addEventModal = (e) => {
        e.preventDefault();
        this.setState({open: true});
    };

    addEvent = (eventId) => {
        this.props.dispatch(addEventToUser(this.props.user.id, eventId));
    };

    handleClose = () => {
        this.setState({open: false});
    }

}

function mapStateToProps(state) {
    const events = state.eventList;

    return {
        events,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(UserEventsList))
export default function statsReducer(statsList = {isFetching: false}, action) {
    if (action.type === 'FETCH_EVENT_STATS') {
        return Object.assign({}, statsList, {
            [action.payload.stats.eventId]: action.payload.stats,
        })
    }
    if (action.type === 'FETCHED_EVENTS_STATS') {
        var stats = {};
        action.payload.stats.forEach(eventStat => {
            stats[eventStat.eventId] = eventStat;
        });
        console.log(stats);
        return Object.assign({}, statsList, {
            isFetching: false,
            ...stats
        })
    }
    if (action.type === 'FETCH_EVENTS_STATS') {
        return Object.assign({}, statsList, {
            isFetching: true,
        })
    }
    return statsList;
}
export default function cartReducer(cart = {
    data: [],
    price: 0,
    isFetching: true
}, action) {
    if (action.type === 'FETCH_CART_PRODUCTS') {
        return Object.assign({}, cart, {
            isFetching: false,
            data: action.payload.products,
            price: calculatePrice(action.payload.products)
        })
    }
    if (action.type === 'ADD_CART_PRODUCT') {
        let data = [...cart.data, action.payload];
        return Object.assign({}, cart, {
            data: data,
            price: calculatePrice(data)
        })
    }
    if (action.type === 'DELETE_CART_PRODUCT') {
        let data = cart.data.filter((value) => value.id !== action.payload.cartProductId);
        return Object.assign({}, cart, {
            data: data,
            price: calculatePrice(data)
        })

    }
    if (action.type === 'UPDATE_CART_PRODUCT') {
        let data = cart.data;
        cart.data.forEach(((value, index) => {
            if(value.id === action.payload.cartProductId){
                data[index].amount = action.payload.amount
            }
        }));

        return Object.assign({}, cart, {
            data: data,
            price: calculatePrice(data)
        })

    }

    return cart;
}

const calculatePrice = (cartProducts) => {

    let price = 0;
    cartProducts.forEach(p => {
        console.log(p)
        price += p.product.price[0].amount * p.amount
    });

    console.log(price)
    return price;
}
import axios from "axios";

function shouldFetch(state) {
    if (state().cart.isFetching) {
        return true;
    }
    return false;
}

export function fetchCartProductsNow() {
    return async function (dispatch, state) {
        axios.get('/api/cart')
            .then(res => {
                dispatch({type: 'FETCH_CART_PRODUCTS', payload: {products: res.data}})
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function fetchCartProducts() {
    return async function (dispatch, state) {
        if (shouldFetch(state)) {
            axios.get('/api/cart')
                .then(res => {
                    dispatch({type: 'FETCH_CART_PRODUCTS', payload: {products: res.data}})
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
export default function productListReducer(categoryList = {
    data: [],
    isFetching: true
}, action) {
    if (action.type === 'FETCH_CATEGORIES') {
        return Object.assign({}, categoryList, {
            isFetching: false,
            data: action.payload.categories
        })
    }
    if (action.type === 'ADD_CATEGORY') {
        return Object.assign({}, categoryList, {
            data: [...categoryList.data, action.payload]
        })
    }
    if (action.type === 'EDIT_CATEGORY') {
        return Object.assign({}, categoryList, {
            data: [...categoryList.data.filter((value) => value.id !== action.payload.id), action.payload].sort((a, b) => a.id - b.id)
        })
    }
    if (action.type === 'DELETE_CATEGORY') {
        return Object.assign({}, categoryList, {
            data: categoryList.data.filter((value) => value.id !== action.payload.categoryId)
        })

    }
    return categoryList;
}
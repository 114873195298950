import axios from "axios";
import {fetchCartProducts, fetchCartProductsNow} from "./fetchCart";

function shouldFetch(state) {
    if (state().eventList.isFetching) {
        return true;
    }
    return false;
}

export const fetchEvents = () => {
    return async function (dispatch, state) {
        if (shouldFetch(state)) {
            axios.get('/api/events')
                .then(res => {
                    dispatch({type: 'FETCH_EVENTS', payload: {events: res.data}})
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};

export const createEvent = (name) => {
    return async function (dispatch, state) {
        axios('/api/events/create', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: JSON.stringify({
                name: name,
            })
        })
            .then(res => {
                console.log(res.data);
                if (res.data.result === 'SUCCESS') {
                    dispatch({type: 'ADD_EVENT', payload: res.data.event});
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
};

export const editEvent = (eventId, name) => {
    return async function (dispatch, state) {
        axios('/api/events/edit', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: JSON.stringify({
                eventId: eventId, 
                name: name
            })
        })
            .then(res => {
                console.log(res.data);
                if (res.data === 'SUCCESS') {
                    dispatch({type: 'EDIT_EVENT', payload: {
                            id: eventId, 
                            name: name
                        }});
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
};

export const selectEvent = (eventId) => {
    return async (dispatch, state) => {
        if (state().loggedAs != null) {
            if (state().loggedAs.eventId === eventId) {
                return;
            }
        }
        axios.get('/api/events/select/' + eventId)
            .then(res => {
                if (res.data === 'SUCCESS') {
                    dispatch({type: 'SELECT_EVENT', payload: eventId})
                    dispatch(fetchCartProductsNow());
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
};

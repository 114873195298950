import axios from "axios";
import Cookies from "js-cookie";
import {fetchCartProducts} from "./fetchCart";

export function logout() {
    return dispatch => {
        Cookies.remove("loginToken");
        dispatch({
            type: 'ACCOUNT_LOGOUT',
        });
    }
}

export function loggedIn(loginData) {
    return function (dispatch, state) {
        dispatch({
            type: 'ACCOUNT_LOGGED_IN',
            payload: {
                token: loginData.token,
                user: loginData.user
            }
        });

        dispatch(fetchCartProducts());
    }
}

function loginFailed(error) {
    return {
        type: 'ACCOUNT_LOGIN_FAILED',
        payload: {
            reason: error
        }
    }
}

function loginError(reason) {
    return {
        type: 'ACCOUNT_LOGIN_ERROR',
        payload: {
            reason: reason,
        }
    }
}

export function loginWithToken(token) {
    return async function (dispatch, state) {
        dispatch({type: 'ACCOUNT_LOGGING_IN'});
        axios('/api/user/logintoken', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: {
                token: token,
            }
        })
            .then(res => {
                console.log(res.data);

                if (res.data.result !== "SUCCESS") {
                    dispatch(loginFailed(res.data.result));
                    return;
                }
                dispatch(loggedIn(res.data))

            })
            .catch(error => {
                console.log(error);
                dispatch(loginError(error));
            });
    }
}

export function login(name, password) {
    return async function (dispatch, state) {
        dispatch({type: 'ACCOUNT_LOGGING_IN'});
        axios('/api/user/login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: {
                user: name,
                password: password
            }
        })
            .then(res => {
                console.log(res.data);

                if (res.data.result !== "SUCCESS") {
                    dispatch(loginFailed(res.data.result));
                    return;
                }
                dispatch(loggedIn(res.data))

            })
            .catch(error => {
                console.log(error);
                dispatch(loginError(error));
            });
    }
}

export function createUserAccount(userName, password, name, surname, group, eventId) {
    return async function (dispatch, state) {
        axios('/api/user/add', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: {
                userName: userName,
                password: password,
                name: name,
                surname: surname,
                group: group,
                eventId: eventId,
            }
        })
            .then(res => {
                console.log(res.data);
                if (res.data.result !== "SUCCESS") {
                    return;
                }
                dispatch({type: "ADD_USER", payload: res.data.user})

            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function addEventToUser(userId, eventId) {
    return async function (dispatch, state) {
        if (state().userList.data.filter((value) => value.id === userId)[0].events.includes(eventId)) {
            return;
        }
        axios('/api/user/edit/events/add', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: {
                userId: userId,
                eventId: eventId
            }
        })
            .then(res => {
                console.log(res.data);
                if (res.data !== "SUCCESS") {
                    return;
                }
                dispatch({type: "ADD_EVENT_TO_USER", payload: {eventId: eventId, userId: userId}})

            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function removeEventToUser(userId, eventId) {
    return async function (dispatch, state) {
        if (!(state().userList.data.filter((value) => value.id === userId)[0].events.includes(eventId))) {
            return;
        }
        axios('/api/user/edit/events/remove', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: {
                userId: userId,
                eventId: eventId
            }
        })
            .then(res => {
                console.log(res.data);
                if (res.data !== "SUCCESS") {
                    return;
                }
                dispatch({type: "REMOVE_EVENT_TO_USER", payload: {eventId: eventId, userId: userId}})

            })
            .catch(error => {
                console.log(error);
            });
    }
}
export default function stockReducer(cashDesk = {
    data: [],
    amount: [],
    isFetching: true
}, action) {
    if (action.type === 'FETCHING_CASHDESK') {
        return {
            isFetching: true,
            data: []
        }
    }
    if (action.type === 'FETCH_CASHDESK') {
        return {
            isFetching: false,
            data: action.payload.records,
            amount: action.payload.amount
        }
    }
    if (action.type === 'ADD_TO_CASHDESK') {
        const amount = action.payload.amount;
        return Object.assign({}, cashDesk, {
            amount: cashDesk.amount + (amount / 1),
            data: [ {
                reason: action.payload.reason,
                amount: action.payload.amount,
                date: new Date()
            }, ...cashDesk.data]
        })
    }
    return cashDesk;
}
import axios from "axios";

export function addCategory(name, color) {
    return async function (dispatch, state) {
        axios('/api/categories/add', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: JSON.stringify({
                name: name,
                color: color
            })
        })
            .then(res => {
                console.log(res.data);
                if (res.data.result === 'SUCCESS') {
                    dispatch({type: 'ADD_CATEGORY', payload: res.data.category});
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function editCategory(categoryId, name, color) {
    return async function (dispatch, state) {
        console.log({
            categoryId: categoryId,
            name: name,
            color: color
        });
        axios('/api/categories/edit', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: JSON.stringify({
                categoryId: categoryId,
                name: name,
                color: color
            })
        })
            .then(res => {
                console.log(res.data);
                if (res.data === 'SUCCESS') {
                    dispatch({
                        type: 'EDIT_CATEGORY', payload: {
                            id: categoryId,
                            name: name,
                            color: color
                        }
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
}
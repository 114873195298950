import {Component} from "react";
import React from "react";
import {connect, ConnectedProps} from "react-redux";
import Layout from "../../components/Layout";
import {fetchStock} from "../../actions/stockActions";
import {fetchEvents} from "../../actions/eventsActions";
import LoadingScreen from "../../components/LoadingScreen";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import {fetchProducts} from "../../actions/fetchProducts";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import withStyles from "@material-ui/core/styles/withStyles";
import {createStyles, TextField, Theme, WithStyles} from "@material-ui/core";
import {RouteComponentProps} from "react-router-dom";
import {Package} from "../../types/Packages";
import PackagesAddProductsRow from "./PackagesAddProductsRow";
import { Fragment } from "react";
import PackageProductRow from "./PackageProductRow";

const styles = (theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(3, 2),
    },
});

type PropsFromRedux = ConnectedProps<typeof connector>

export interface PackageProductsListProps extends WithStyles<typeof styles>, PropsFromRedux {
    package: Package,
    send: boolean,
    setAmount: (productId: number, amount: number) => void,
    setTempValue: (productId: number, amount: string) => void
}

export interface PackageProductsListState {
    search: string
}

class PackageProductsList extends Component<PackageProductsListProps,PackageProductsListState> {

    constructor(props: PackageProductsListProps){
        super(props);

        this.state = {search: ""}
    }

    componentDidMount() {
        this.props.fetchProducts();
    }

    render(){
        if(this.props.products.isFetching){
            return (
                <LoadingScreen reason={"Načítám obsah balíčku"}/>
            );
        }
        return (
            <Fragment>
                <Typography variant={"h4"}>
                    Obsah
                </Typography>
                <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Produkt</TableCell>
                        <TableCell align="right">
                            <TextField style={{margin: '5px'}}
                                id="outlined-search"
                                label="Vyhledat"
                                value={this.state.search}
                                onChange={e => this.handleChange(e)}
                                margin="normal"
                                variant="outlined"
                                name="search"
                                type="search"
                                size={"small"}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
        {
            this.props.package.products.filter(p => {
                if(this.state.search == ""){
                    return true;
                }
                const productInfo = this.props.products.data.find(pr => pr.id == p.productId);
                return productInfo.name.includes(this.state.search);
            }).map(product => {
                const productInfo = this.props.products.data.find(pr => pr.id == product.productId);
                return <PackageProductRow productName={productInfo ? productInfo.name : "Unknown product"} productId={product.productId} amount={product.amount} setAmount={this.props.setAmount} setTempValue={this.props.setTempValue} readOnly={this.props.send}/>;
            })
        }
        </Table>
        </Fragment>
    );
    }
    
    handleChange = (e) => {
        // @ts-ignore
        this.setState({
            [e.target.name]: e.target.value
        });
    }
}

const mapState = (state) => ({
    products: state.productList,
})

const mapDispatch = (dispatch) => {
    return {
        fetchProducts: () => dispatch(fetchProducts()),
    }
}

const connector = connect(mapState, mapDispatch)

export default connector(withStyles(styles)(PackageProductsList));
import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import {Divider, withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {fetchUsers} from "../actions/fetchUsers";
import Layout from "../components/Layout";
import LoadingScreen from "../components/LoadingScreen";
import Button from "@material-ui/core/Button";
import UserEventsList from "../components/UserEventsList";

const styles = {
    paper: {
        width: '1',
    },
};

class UserEdit extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.dispatch(fetchUsers());
    }

    render() {
        if (this.props.users.isFetching) {
            return (
                <Layout>
                    <LoadingScreen reason={"Načítám uživatele..."}/>
                </Layout>
            );
        }

        const user = this.props.users.data.filter(u => u.userName === this.props.match.params.username)[0];
        return (
            <Layout>
                <Grid container direction={"row"} justify={"space-between"} alignItems={"center"} spacing={1}>
                    <Typography variant={"h3"}>
                        {user.userName}
                    </Typography>
                    <Button variant="contained" size="medium" color="secondary" onClick={this.handleDisable}>
                        Disable
                    </Button>
                </Grid>
                <Divider style={
                    {
                        marginBottom: '5px'
                    }
                }/>
                <Grid container>
                    <Grid item xs={12} sm={8} md={6}>
                        <UserEventsList user={user} userEvents={user.events}/>
                    </Grid>
                </Grid>
            </Layout>
        );
    }

    //Buttons actions
    handleDisable = (e) => {

    }

}

function mapStateToProps(state) {
    const users = state.userList;
    return {
        users,
    };

}

export default connect(mapStateToProps)(withStyles(styles)(UserEdit));
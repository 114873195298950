import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {deleteCartProduct, updateCartProduct} from "../actions/cartActions";
import {connect} from "react-redux";

class CartProduct extends React.Component {

    classes = makeStyles(theme => ({
        button: {
            margin: theme.spacing(1),
        }
    }));

    constructor(props){
        super(props);
    }

    render(){
        return (
            <Grid item xs={12} className="myButton" style={{backgroundColor: 'white'}}>
                <Grid container style={{borderTop: '1px solid #ababab', margin: '0px',padding: '0px',paddingTop: '5px', paddingBottom: '5px', borderLeft: '0px',  borderRight: '0px'}}>
                    <Grid item xs={7} style={{alignItems: 'center', justifyContent: 'center', display: 'flex', padding: '0px'}}>
                        {this.props.name}<br/>
                    </Grid>
                    <Grid item xs={5} style={{alignItems: 'center', justifyContent: 'center', display: 'flex', padding: '0px'}}>
                        <Grid container>
                            <Grid item container xs={4} style={{alignItems: 'center', justifyContent: 'center', padding: '0px'}}>
                                <Button variant="contained" color="primary" className={this.classes.button} style={{fontSize: '20px', width: '100%', height: '100%', borderBottomRightRadius: '0px' ,borderTopRightRadius: '0px', minWidth: '35px'}}
                                        onClick={this.addProduct}>
                                    +
                                </Button>
                            </Grid>
                            <Grid item xs={4} style={{borderTop: '1px solid #ababab', borderBottom: '1px solid #ababab', minWidth: '32px'}}>
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    padding: '0px',
                                    
                                }}>
                                    {this.props.amount}
                                </div>
                            </Grid>
                            <Grid item container xs={4} style={{alignItems: 'center', justifyContent: 'center', padding: '0px'}}>
                                <Button variant="contained" color="secondary" className={this.classes.button} style={{fontSize: '20px', width: '100%', height: '100%', borderBottomLeftRadius: '0px' ,borderTopLeftRadius: '0px', minWidth: '35px'}}
                                        onClick={this.removeProduct}>
                                     -
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    addProduct = (e) => {
        e.preventDefault();
        this.updateAmount(this.props.amount + 1);

    };

    removeProduct = (e) => {
        e.preventDefault();
        this.updateAmount(this.props.amount - 1);

    };

    updateAmount = (newAmount) => {
        if(newAmount === 0){
            this.props.dispatch(deleteCartProduct(this.props.id));
            return;
        }
        this.props.dispatch(updateCartProduct(this.props.id, newAmount));
    };
}

export default connect()(CartProduct);
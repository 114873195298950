export default function stockReducer(stock = {
    data: [],
    isFetching: true
}, action) {
    if (action.type === 'FETCHING_STOCK_STARTED') {
        return {
            isFetching: true,
            data: []
        }
    }
    if (action.type === 'FETCH_STOCK') {
        return Object.assign({}, stock, {
            isFetching: false,
            data: action.payload.products
        })
    }
    if (action.type === 'ADD_TO_STOCK') {
        const amount = action.payload.amount;
        const productId = action.payload.productId;
        const data = stock.data;

        data.forEach(p => {
            if (p.productId === productId) {
                p.amount = p.amount + (amount / 1);
            }
        });

        return Object.assign({}, stock, {
            data: data
        })

    }
    return stock;
}
import {Component} from "react";
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import history from "../history";
import {withStyles} from "@material-ui/core";
import EditProductModal from "./modals/EditProductModal";
import Dialog from "@material-ui/core/Dialog";
import EditEventModal from "./modals/EditEventModal";

const styles = theme => ({
    middleButton: {
        margin: theme.spacing(1),
        backgroundColor: '#f9a825',
        color: 'white',
        '&:hover': {
            backgroundColor: '#f57f17',
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: '#f9a825',
            },
        },
    },
    cashDeskButton: {
        marginLeft: theme.spacing(1),
        backgroundColor: '#4caf50',
        color: 'white',
        '&:hover': {
            backgroundColor: '#418141',
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: '#4caf50',
            },
        },
    },
});

class EventRow extends Component {

    constructor(props) {
        super(props);
        
        this.state = {open: false}
    }

    render() {
        return (
            <TableRow>
                <TableCell>{this.props.event.name}</TableCell>
                <TableCell align="right">
                    <Button variant="contained" size="small" color="primary" onClick={this.overview}>
                        Přehled
                    </Button>
                    <Button variant="contained" size="small" color="inherit"
                            className={this.props.classes.cashDeskButton} onClick={this.cashdesk}>
                        Pokladna
                    </Button>
                    <Button variant="contained" size="small" color="inherit"
                            className={this.props.classes.middleButton} onClick={this.stock}>
                        Sklad
                    </Button>
                    <Button variant="contained" size="small" color="secondary" onClick={this.handleEdit}>
                        Upravit
                    </Button>
                </TableCell>
                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <EditEventModal event={this.props.event} handleClose={this.handleClose}/>
                </Dialog>
            </TableRow>
        );
    }

    handleEdit = e => {
        e.preventDefault();
        this.setState({open: true});
        
     //   history.push("/events/edit/" + this.props.event.id);
    };

    handleClose = () => {
        this.setState({open: false});
    };

    stock = e => {
        e.preventDefault();

        history.push("/stock/" + this.props.event.id);
    };

    cashdesk = e => {
        e.preventDefault();

        history.push("/cashdesk/" + this.props.event.id);
    };

    overview = e => {
        e.preventDefault();

        history.push("/stats/" + this.props.event.id);
    };

}

export default withStyles(styles)(EventRow);
import React from 'react';
import Logo from '../images/logoImage.png'
import {Redirect} from "react-router-dom";
import {loggedIn, login, loginWithToken} from "../actions/accountsActions";
import {connect} from "react-redux";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LoadingScreen from "../components/LoadingScreen";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {error: false, username: "", password: ""}
    }

    render() {
        console.log(this.props.loggedIn);
        if (this.props.loggedIn) {
            return <Redirect to="/"/>
        }
        if (this.props.loginToken != null) {
            this.props.dispatch(loginWithToken(this.props.loginToken));
        }

        if (this.props.loggingIn) {
            return <LoadingScreen reason={"Logging in.. Please wait."}/>
        }

        return (
            <Container>
                <Grid container justify={"center"} alignContent={"center"}>
                    <Paper style={{maxWidth: '500px', minWidth: '350px', textAlign: 'center'}}>
                        <form onSubmit={e => this.loginButton(e)}>
                            <br/>
                            <img src={Logo}/>
                            <br/>
                            <h1>Přihlas se</h1>
                            <br/>
                            <TextField style={{margin: '10px'}}
                                       id="outlined-name"
                                       label="Uživatelské jméno"
                                       value={this.state.username}
                                       onChange={e => this.handleChange(e)}
                                       margin="normal"
                                       variant="outlined"
                                       name="username"
                                       error={this.state.error}
                            />
                            <br/>
                            <TextField style={{margin: '10px'}}
                                       error={this.state.error}
                                       id="outlined-password"
                                       label="Zadejte heslo"
                                       value={this.state.password}
                                       onChange={e => this.handleChange(e)}
                                       margin="normal"
                                       variant="outlined"
                                       name="password"
                                       type="password"
                                       helperText={this.state.error ? "Neplatné jméno nebo heslo" : ""}
                            />
                            <br/>
                            <Button type="submit" variant="contained" color="primary" size={"small"}
                                    style={{margin: '15px'}}>Přihlásit se</Button>
                        </form>
                    </Paper>
                </Grid>
            </Container>
        );
    }

    handleChange(e) {
        console.log(e.target.name);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    loginButton(e) {
        e.preventDefault();

        this.props.dispatch(login(this.state.username, this.state.password));
        this.setState({error: true})


    }

}

function mapStateToProps(state) {
    const loggedIn = state.loggedIn;
    const loginToken = state.loginToken;
    const loggingIn = state.loggingIn;
    return {
        loggedIn,
        loginToken,
        loggingIn
    }
}

export default connect(mapStateToProps)(Login);
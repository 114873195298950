import axios from "axios";

export function fetchReceipts(eventId) {
    return async function (dispatch, state) {
        dispatch({type: 'FETCHING_RECEIPTS'});
        axios.get('/api/receipts/' + eventId)
            .then(res => {
                console.log(res.data);
                dispatch({type: 'FETCH_RECEIPTS', payload: {receipts: res.data}})
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function fetchMoreReceipts(eventId) {
    return async function (dispatch, state) {
        if (state().receipts.isFetching || state().receipts.isFetchingAnother) {
            return;
        }
        dispatch({type: 'FETCHING_MORE_RECEIPTS'});
        axios.get('/api/receipts/' + eventId + "?startId=" + state().receipts.data[0].id + "&skip=" + state().receipts.data.length)
            .then(res => {
                console.log(res.data);
                dispatch({type: 'FETCH_MORE_RECEIPTS', payload: {receipts: res.data}})
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function resetReceipt(receiptId) {
    return async function (dispatch, state) {
        axios.get('/api/receipts/reset/' + receiptId)
            .then(res => {
                if (res.data === "SUCCESS") {
                    dispatch({type: 'RESET_RECEIPT', payload: {receiptId: receiptId}})
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
}
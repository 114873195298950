import axios from "axios";
import history from "../history"

const AFFILIATE_KEY = "60bd3b04-480e-4a74-8c40-041fe984038b";

export const addCartProduct = (productId) => {
    return async function (dispatch, state) {
        axios.get('/api/cart/add/' + productId)
            .then(res => {
                console.log(res.data)
                if (state().cart.data.filter(item => item.id === res.data.id).length === 0) {
                    dispatch({
                        type: 'ADD_CART_PRODUCT',
                        payload: res.data
                    })
                } else {
                    dispatch({
                        type: 'UPDATE_CART_PRODUCT',
                        payload: {
                            cartProductId: res.data.id,
                            amount: res.data.amount,
                        }
                    })
                }
            }).catch(error => console.log(error));
    }
};

export const deleteCartProduct = (cartProductId) => {
    return async (dispatch, state) => {
        console.log(state().cart.data)
        let productId = state().cart.data.filter(value => value.id === cartProductId)[0].product.id;

        axios('/api/cart/update', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: JSON.stringify({
                productId: productId,
                amount: 0
            })
        })
            .then(res => {
                console.log(res.data);
                if (res.data === 'SUCCESS') {
                    dispatch({
                        type: 'DELETE_CART_PRODUCT',
                        payload: {
                            cartProductId: cartProductId,
                        }
                    })
                }
            }).catch(error => console.log(error));
    }
};

export const checkoutCart = (payType) => {
    return async (dispatch, state) => {
        if (state().cart.data.size === 0) {
            return;
        }

        axios('/api/receipts/checkout/' + payType, {
            method: 'GET',
        })
            .then(res => {
                console.log(res.data);

                if (res.data.paid === 0) {

                    let price = res.data.amount;
                    let currency = res.data.currency;
                    let receiptId = res.data.id;
                    if (payType === "CREDIT_CARD_IOS") {
                        window.open("sumupmerchant://pay/1.0?amount=" + price + "&currency=" + currency + "&affiliate-key=" + AFFILIATE_KEY + "&title=Receipt #" + receiptId)
                    } else if (payType === "CREDIT_CARD_ANDROID") {
                        window.open("sumupmerchant://pay/1.0?total=" + price + "&currency=" + currency + "&affiliate-key=" + AFFILIATE_KEY + "&title=Receipt #" + receiptId)
                    }
                }
                dispatch({type: 'FETCH_CART_PRODUCTS', payload: {products: []}});
                history.push("/");
            })
            .catch(error => {
                console.log(error)
            });
    }
};

export const updateCartProduct = (cartProductId, amount) => {
    return async (dispatch, state) => {
        console.log(state().cart.data)
        let productId = state().cart.data.filter(value => value.id === cartProductId)[0].product.id;

        axios('/api/cart/update', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: JSON.stringify({
                amount: amount,
                productId: productId,
            })
        })
            .then(res => {
                console.log(res.data);
                if (res.data === 'SUCCESS') {
                    dispatch({
                        type: 'UPDATE_CART_PRODUCT',
                        payload: {
                            cartProductId: cartProductId,
                            amount: amount,
                        }
                    })
                }
            }).catch(error => console.log(error));
    }
};




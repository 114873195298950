import axios from "axios";

export function fetchStock(eventId) {
    return async function (dispatch, state) {
        dispatch({type: 'FETCHING_STOCK_STARTED'});
        axios.get('/api/stock/' + eventId)
            .then(res => {
                dispatch({type: 'FETCH_STOCK', payload: {products: res.data}})
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function addToStock(eventId, productId,reason, amount) {
    return async function (dispatch, state) {
        axios('/api/stock/add', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: {
                eventId: eventId,
                productId: productId,
                reason: reason,
                amount: amount
            }
        })
            .then(res => {
                console.log(res.data);

                if (res.data !== "SUCCESS") {
                    return;
                }
                dispatch({
                    type: 'ADD_TO_STOCK',
                    payload: {
                        productId: productId,
                        amount: amount
                    }
                })

            })
            .catch(error => {
                console.log(error);
            });
    }
}
export default function eventListReducer(eventList = {
    data: [],
    isFetching: true
}, action) {
    if (action.type === 'FETCH_EVENTS') {
        return Object.assign({}, eventList, {
            isFetching: false,
            data: action.payload.events
        })
    }
    if (action.type === 'ADD_EVENT') {
        return Object.assign({}, eventList, {
            data: [...eventList.data, action.payload]
        })
    }
    if (action.type === 'EDIT_EVENT') {
        return Object.assign({}, eventList, {
            data: [...eventList.data.filter((value) => value.id !== action.payload.id), action.payload].sort((a, b) => a.id - b.id)
        })
    }
    return eventList;
}
import React, {Component} from 'react';
import './App.css';
import {Route, Router} from "react-router-dom";
import Cart from "./sites/Cart";
import CategoryList from "./sites/CategoryList";
import Login from "./sites/Login";
import Register from "./sites/Register";
import thunk from 'redux-thunk';
import combineReducers from './reducers'
import {applyMiddleware, createStore} from "redux";
import {Provider} from "react-redux";
import httpService from "./httpService";
import history from "./history"
import EventSelect from "./sites/EventSelect";
import Users from "./sites/Users";
import UserEdit from "./sites/UserEdit";
import Events from "./sites/Events";
import EventStats from "./sites/EventStats";
import Stock from "./sites/Stock";
import Overview from "./sites/Overview";
import AdminProducts from "./sites/AdminProducts";
import Categories from "./sites/Categories";
import CashDesk from "./sites/CashDesk";
import ProductHistory from "./sites/ProductHistory";
import ReceiptsTray from "./sites/ReceiptsTray";
import MysteryBoxGenerator from "./sites/MysteryBoxGenerator";
import PackagesSite from "./sites/PackagesSite.tsx";
import PackageSite from "./sites/PackageSite.tsx";

export default class App extends Component {

    constructor(props) {
        super(props);

        this.state = {height: props.height};
    }

    componentWillMount() {
        this.setState({height: window.innerHeight + 'px'});
    }

    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/register" component={Register}/>
                    <Route exact path="/" component={CategoryList}/>
                    <Route exact path="/cart" component={Cart}/>

                    <Route exact path="/event" component={EventSelect}/>
                    <Route exact path="/events" component={Events} />

                    <Route exact path="/overview" component={Overview}/>

                    <Route exact path="/stats" component={EventStats}/>
                    <Route exact path="/stats/:eventId" component={EventStats}/>

                    <Route exact path="/stock" component={Stock}/>
                    <Route exact path="/stock/:eventId" component={Stock}/>

                    <Route exact path="/cashdesk" component={CashDesk}/>
                    <Route exact path="/cashdesk/:eventId" component={CashDesk}/>

                    <Route exact path="/receipts" component={ReceiptsTray}/>
                    <Route exact path="/receipts/:eventId" component={ReceiptsTray}/>

                    <Route exact path="/categories" component={Categories} />
                    <Route exact path="/products" component={AdminProducts} />
                    <Route exact path="/mysterybox" component={MysteryBoxGenerator} />
                    <Route exact path="/packages" component={PackagesSite} />
                    <Route exact path="/packages/:eventId" component={PackagesSite} />
                    
                    <Route exact path="/packages/detail/:packageId" component={PackageSite} />
                    
                    <Route exact path="/users" component={Users} />
                    <Route path="/users/edit/:username" component={UserEdit}/>

                    <Route path="/products/history/:eventId/:productId" component={ProductHistory}/>
                </Router>
            </Provider>
        );
    }
}


const store = createStore(combineReducers, applyMiddleware(thunk));

httpService.setupInterceptors(store, history);

import React from "react";
import Typography from "@material-ui/core/Typography";
import {Divider} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/styles/withStyles";
import LoadingScreen from "../LoadingScreen";


const styles = theme => ({
    paper: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
    header: {
        marginBottom: theme.spacing(0.5),
    },
    total: {
        textAlign: "center",
        padding: theme.spacing(1),
    },
});

class TotalProfits extends React.Component {
    
    render() {
        if (this.props.stats.isFetching || this.props.products.isFetching || this.props.categories.isFetching || this.props.events.isFetching ) {
            return (
                <Paper className={this.props.classes.paper}>
                    <LoadingScreen reason={"Stahuji data"} />
                </Paper>
            );
        }
        var total = 0
        for(const key of Object.entries(this.props.stats)){
            if (key[0] == 'isFetching') continue;
            if (key[1].archived) continue;
            total = total + key[1].totalProfits
        }
        
        return (
            <Paper className={this.props.classes.paper}>
                <Typography variant={"h6"} className={this.props.classes.header}>
                    Celkový zisk
                </Typography>
                <Divider/>
                <Typography variant={"h2"} component={"div"} className={this.props.classes.total}>
                    {formatNumber(total)} Kč
                </Typography>
            </Paper>
        )
    };

}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

function mapStateToProps(state) {
    const stats = state.statsList;
    const products = state.productList;
    const categories = state.categoryList;
    const events = state.eventList;
    return {
        stats,
        products,
        categories,
        events
    };
}

export default connect(mapStateToProps)(withStyles(styles)(TotalProfits));
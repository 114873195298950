import React, {Fragment} from 'react';
import Product from "./Product";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import connect from "react-redux/es/connect/connect";

class ProductList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        if (!this.props.isFetched) {
            return <div>Loading</div>
        }
        if (this.props.products.filter(value => {
            return value.categoryId === this.props.categoryId && !value.hidden
        }).length === 0) {
            // If category does not contains any product dont show it
            return <Fragment/>
        }
        console.log(this.props.products)
        return (
            <div>
                <Grid container style={{fontWeight: '800', fontSize: '32', marginTop: '5px'}}>
                    <Grid item style={{marginLeft: '10px'}}>{this.props.categoryName}</Grid>
                </Grid>
                <Divider style={{marginBottom: '5px'}}/>
                <Grid container spacing={1}>
                    {
                        this.props.products.filter(value => {
                            return value.categoryId === this.props.categoryId && !value.hidden
                        }).map(product => {
                            return <Product key={product.id} id={product.id} name={product.name}
                                            picture={product.picture}
                                            price={product.price}/>
                        })
                    }
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let products = state.productList.data;
    let isFetched = !state.productList.isFetching;
    return {
        products,
        isFetched
    }
}

export default connect(mapStateToProps)(ProductList);
import React from 'react';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CropFreeIcon from '@material-ui/icons/CropFree';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles, useTheme} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import TimelineIcon from '@material-ui/icons/Timeline';
import SuperVisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ViewListIcon from '@material-ui/icons/ViewList';
import CachedIcon from '@material-ui/icons/Cached';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Logo from '../images/logoImage.png'
import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {logout} from "../actions/accountsActions";
import Fab from "@material-ui/core/Fab";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from "@material-ui/core/Collapse";
import TodayIcon from '@material-ui/icons/Today';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    toolbarSpace: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    fabButton: {
        zIndex: '1',
        position: 'absolute',
        top: theme.spacing(3),
        right: theme.spacing(1.5)
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    nestedItem: {
        paddingLeft: theme.spacing(4),
    },
}));

function Layout(props) {
    const {container} = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
    }

    function handleAdminClick() {
        setOpen(!open);
    }

    function admin(user) {
        if (user == null || user.group < 10) {
            return;
        }
        return (
            <ListItem button key={"Administration"} onClick={handleAdminClick}>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText primary={"Administrace"} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
        );
    }

    function adminList(user) {
        if (user == null || user.group < 10) {
            return;
        }
        return (
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List>
                    <ListItem button key={"Overview"} component={Link} to={"/overview"} className={classes.nestedItem}>
                        <ListItemIcon><TimelineIcon /></ListItemIcon>
                        <ListItemText primary={"Přehled"} />
                    </ListItem>
                    <ListItem button key={"Users"} component={Link} to={"/users"} className={classes.nestedItem}>
                        <ListItemIcon><SuperVisorAccountIcon /></ListItemIcon>
                        <ListItemText primary={"Uživatelé"} />
                    </ListItem>
                    <ListItem button key={"Events"} component={Link} to={"/events"} className={classes.nestedItem}>
                        <ListItemIcon><TodayIcon /></ListItemIcon>
                        <ListItemText primary={"Seznam akcí"} />
                    </ListItem>
                    <ListItem button key={"Categories"} component={Link} to={"/categories"} className={classes.nestedItem}>
                        <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
                        <ListItemText primary={"Seznam kategorií"} />
                    </ListItem>
                    <ListItem button key={"AdminProducts"} component={Link} to={"/products"} className={classes.nestedItem}>
                        <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
                        <ListItemText primary={"Seznam produktů"} />
                    </ListItem>
                </List>
            </Collapse>
        );
    }

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <img src={Logo}/>
            </div>
            <Divider/>
            <List>
                <ListItem button key={"Products"} component={Link} to={"/"}>
                    <ListItemIcon><LibraryBooksIcon/></ListItemIcon>
                    <ListItemText primary={"Produkty"}/>
                </ListItem>
                <ListItem button key={"Cart"} component={Link} to={"/cart"}>
                    <ListItemIcon><ShoppingCartIcon/></ListItemIcon>
                    <ListItemText primary={"Košík"}/>
                </ListItem>
            </List>
            <Divider/>
            <List>
                <ListItem button key={"stats"} component={Link} to={"/stats"}>
                    <ListItemIcon><TimelineIcon/></ListItemIcon>
                    <ListItemText primary={"Statistiky"}/>
                </ListItem>
                <ListItem button key={"Stock"} component={Link} to={"/stock"}>
                    <ListItemIcon><ViewListIcon/></ListItemIcon>
                    <ListItemText primary={"Skladové zásoby"}/>
                </ListItem>
                <ListItem button key={"CashDesk"} component={Link} to={"/cashdesk"}>
                    <ListItemIcon><MoneyIcon/></ListItemIcon>
                    <ListItemText primary={"Pokladna"}/>
                </ListItem>
                <ListItem button key={"ReceiptsTray"} component={Link} to={"/receipts"}>
                    <ListItemIcon><ReceiptIcon/></ListItemIcon>
                    <ListItemText primary={"Zásobník dokladů"}/>
                </ListItem>
                <ListItem button key={"MysteryBox"} component={Link} to={"/mysterybox"}>
                    <ListItemIcon><CropFreeIcon/></ListItemIcon>
                    <ListItemText primary={"Mystery Boxy"}/>
                </ListItem>
                <ListItem button key={"ChangeEvent"} component={Link} to={"/event"}>
                    <ListItemIcon><CachedIcon/></ListItemIcon>
                    <ListItemText primary={"Změnit akci"}/>
                </ListItem>
                <ListItem button key={"Packages"} component={Link} to={"/packages"}>
                    <ListItemIcon><LocalShippingIcon/></ListItemIcon>
                    <ListItemText primary={"Balíčky"}/>
                </ListItem>
                {
                    admin(props.user)
                }
                {
                    adminList(props.user)
                }
                <ListItem button key={"LogOut"} onClick={() => {
                    clickLogout(props.dispatch)
                }}>
                    <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                    <ListItemText primary={"Odhlásit se"}/>
                </ListItem>
            </List>
        </div>
    );

    if (!props.loggedIn) {
        return <Redirect to={"/login"}/>
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={() => {
                            handleDrawerToggle()
                        }}
                        className={classes.menuButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Pokladna
                    </Typography>
                    <div className={classes.toolbar}>
                        <Fab variant="extended" size="large" color="secondary" aria-label="Cart"
                             className={classes.fabButton} component={Link} to={"/cart"}>
                            {props.cartPrice} Kč
                            <ShoppingCartIcon className={"margin"}/>
                        </Fab>
                    </div>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        container={props.container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={() => {
                            handleDrawerToggle()
                        }}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbarSpace}/>
                {props.children}
            </main>
        </div>
    );
}


function clickLogout(dispatch) {
    dispatch(logout())
}

function mapStateToProps(state) {
    const loggedIn = state.loggedIn;
    const user = state.loggedAs;
    const cartPrice = state.cart.price;
    return {
        loggedIn,
        cartPrice,
        user,
    }
}

export default connect(mapStateToProps)(Layout);
import {Component} from "react";
import Layout from "../components/Layout";
import React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import Table from "@material-ui/core/Table";
import LoadingScreen from "../components/LoadingScreen";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import ProductRow from "../components/ProductRow";
import Dialog from "@material-ui/core/Dialog";
import AddProductModal from "../components/modals/AddProductModal";
import {fetchAdminProducts, fetchProducts} from "../actions/fetchProducts";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";

const styles = {
    paper: {
        width: '1',
    },
};

class AdminProducts extends Component {

    constructor(props){
        super(props);

        this.state = {opened: false}
    }

    componentDidMount() {
        this.props.dispatch(fetchAdminProducts());
    }

    render(){
        if(this.props.products.isFetching || this.props.categories.isFetching){
            return (
                <Layout>
                    <LoadingScreen reason={"Načítám produkty..."}/>
                </Layout>
                )
        }
        return (
            <Layout>
                <Paper className={this.props.classes.paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Produkt</TableCell>
                                <TableCell>Kategorie</TableCell>
                                <TableCell align="right">Cena</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        {this.props.products.data.map(product => {
                            let category = null;
                            this.props.categories.data.forEach(c => {
                                if(c.id === product.categoryId){
                                    category = c;
                                }
                            });
                            return (
                                <ProductRow product={product} category={category} categories={this.props.categories.data}/>
                            );
                            }

                        )}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">
                                <Button variant="contained" size="small" color="primary" onClick={e => this.createProduct(e)}>
                                    Přidat
                                </Button>
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Dialog open={this.state.opened} onClose={this.handleClose}>
                        <AddProductModal categories={this.props.categories.data} handleClose={this.handleClose}/>
                    </Dialog>
                </Paper>
            </Layout>
        );
    }

    createProduct = (e) => {
        e.preventDefault();

        this.setState({opened: true});
    }

    handleClose = () => {
        this.setState({opened: false});
    }



}

function mapStateToProps(state) {
    const products = state.productList;
    const categories = state.categoryList;

    return {
        products,
        categories,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AdminProducts))
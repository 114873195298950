import React, {Props} from 'react';
import {connect, ConnectedProps} from "react-redux";
import Layout from "../components/Layout";
import LoadingScreen from "../components/LoadingScreen";
import Grid from "@material-ui/core/Grid";
import {createStyles, Divider, StyleRules, Theme, WithStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import PackagesList from "../components/pkgs/PackagesList";
import { RouteComponentProps } from 'react-router-dom';
import {Package} from "../types/Packages";
import history from "../history"

const styles = (theme: Theme) => createStyles({
    paper: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
    paper2: {
        width: '1',
        padding: theme.spacing(3, 2),
        paddingTop: theme.spacing(1)
    },
    header: {
        marginBottom: theme.spacing(0.5),
    },
    total: {
        textAlign: "center",
        padding: theme.spacing(1),
    },
    generateButton: {
        margin: theme.spacing(1),
    },
    completeButton: {
        margin: theme.spacing(1),
        backgroundColor: '#f9a825',
        color: 'white',
        '&:hover': {
            backgroundColor: '#f57f17',
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: '#f9a825',
            },
        },
    },
    discardButton: {
        margin: theme.spacing(1),
    }
});

type PropsFromRedux = ConnectedProps<typeof connector>

export interface PackagesSiteProps extends WithStyles<typeof styles>, PropsFromRedux, RouteComponentProps<{eventId?: string}> {
    
}

export interface PackagesSiteState {
    isFetching: boolean,
    packages: Package[],
    eventId: number
}

class PackagesSite extends React.Component<PackagesSiteProps, PackagesSiteState> {

    constructor(props: PackagesSiteProps) {
        super(props);

        let eventId = 0;
        if (this.props.user != null) {
            eventId = this.props.match.params.eventId != null ? this.props.match.params.eventId : this.props.user.eventId;
        }
        
        this.state = {eventId: eventId, isFetching: true, packages: []};
    }

    componentDidMount() {
        axios.get(`/api/packages/event/${this.state.eventId}`)
            .then(res => {
                console.log(res.data);
                if(res.data === undefined || res.data == null){
                    this.setState({isFetching: false});
                    return;
                }
                this.setState({isFetching: false, packages: res.data});
            })
            .catch(error => console.log(error));
    }

    render() {
        if(this.state.isFetching){
            return (
                <Layout>
                    <LoadingScreen/>
                </Layout>
            )
        }
        return (
            <Layout>
                <Grid container spacing={1}>
                    <Grid item md={6} sm={12} xs={12}>
                        <PackagesList packages={this.state.packages.filter(pc => pc.sendDate != null && pc.deliveryDate == null)} displayName={"Na cestě"}/>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <PackagesList packages={this.state.packages.filter(pc => pc.deliveryDate != null)} displayName={"Doručené"}/>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <PackagesList packages={this.state.packages.filter(pc => pc.sendDate == null)} displayName={"Balíčky v přípravě"} button={{displayName: "Nový", action: this.newPackage}}/>
                    </Grid>
                </Grid>
            </Layout>
        );
    }
    
    newPackage = () => {
        axios(`/api/packages/event/${this.state.eventId}/create`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: {}
        }).then(res => {
            console.log(res.data);
            if(res.data === undefined || res.data == null){
                return;
            }
            history.push("/packages/detail/" + res.data.id)
        })
            .catch(error => {
                console.log(error)
            });
    }
    
}


const mapState = (state) => ({
    user: state.loggedAs
})

const mapDispatch = {
    
}

const connector = connect(mapState, mapDispatch)

export default connector(withStyles(styles)(PackagesSite))
import {combineReducers} from "redux";
import productListReducer from "./productListReducer";
import categoryListReducer from "./categoryListReducer";
import {loggedAsReducer, loggedInReducer, loggingInReducer, loginTokenReducer} from "./accountReducers";
import cartReducer from "./cartReducer";
import eventListReducer from "./eventListReducer";
import userListReducer from "./userListReducer";
import stockReducer from "./stockReducer";
import statsReducer from "./statsReducer";
import cashDeskReducer from "./cashDeskReducer";
import receiptsReducer from "./receiptsReducer";


export default combineReducers({
    productList: productListReducer,
    categoryList: categoryListReducer,
    cart: cartReducer,
    loggedIn: loggedInReducer,
    loginToken: loginTokenReducer,
    eventList: eventListReducer,
    loggedAs: loggedAsReducer,
    loggingIn: loggingInReducer,
    userList: userListReducer,
    stock: stockReducer,
    statsList: statsReducer,
    cashDesk: cashDeskReducer,
    receipts: receiptsReducer,
});
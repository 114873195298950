import React from 'react';
import {connect} from "react-redux";
import Layout from "../components/Layout";
import {fetchCashDesk} from "../actions/cashDeskActions";
import LoadingScreen from "../components/LoadingScreen";
import Grid from "@material-ui/core/Grid";
import EventTotalSales from "../components/stats/EventTotalSales";
import DailyTotalSalesChart from "../components/stats/DailyTotalSalesChart";
import Paper from "@material-ui/core/Paper";
import {Divider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import UserRow from "../components/UserRow";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import AddEventModal from "../components/modals/AddEventModal";
import Dialog from "@material-ui/core/Dialog";
import AddMoneyToCashDesk from "../components/modals/AddMoneyToCashDesk";
import axios from "axios";
import {fetchProducts} from "../actions/fetchProducts";
import {fetchEvents} from "../actions/eventsActions";

const styles = theme => ({
    paper: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
    paper2: {
        width: '1',
        padding: theme.spacing(3, 2),
        paddingTop: theme.spacing(1)
    },
    header: {
        marginBottom: theme.spacing(0.5),
    },
    total: {
        textAlign: "center",
        padding: theme.spacing(1),
    },
});


class CashDesk extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                isFetching: true,
                data: {}
            }
        }
    }

    componentDidMount() {
        axios.get('/api/products/history/' + this.props.match.params.eventId + "/" + this.props.match.params.productId)
            .then(res => {
                console.log(res.data)

                this.setState({
                    data: {
                        isFetching: false,
                        data: res.data.records,
                    }
                })
            }).catch(error => console.log(error));

        this.props.dispatch(fetchProducts());
        this.props.dispatch(fetchEvents());
    }

    render() {
        if (this.state.data.isFetching || this.props.events.isFetching || this.props.products.isFetching) {
            return (
                <Layout>
                    <LoadingScreen reason={"Přepočítávám data"}/>
                </Layout>
            );
        }

        return (
            <Layout>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Paper className={this.props.classes.paper2}>
                            <Typography variant={"h6"} className={this.props.classes.header}>
                                {
                                    this.props.events.data.map(e => {
                                        if (e.id == this.props.match.params.eventId) {
                                            return e.name;
                                        }
                                    })
                                } - {
                                this.props.products.data.map(e => {
                                    if (e.id == this.props.match.params.productId) {
                                        return e.name;
                                    }
                                })}

                            </Typography>
                            <Divider/>
                            <br/>
                            <Table>
                                {this.state.data.data.map(record => {
                                    let color = record.amount > 0 ? '#81C784' : '#E57373';
                                    let date = new Date(record.date);
                                    return (
                                        <TableRow style={{backgroundColor: color}}>
                                            <TableCell>{date.getDate()}.{date.getMonth() + 1} {date.getFullYear()}</TableCell>
                                            <TableCell>{record.reason}</TableCell>
                                            <TableCell>
                                                {formatNumber(record.amount)} ks
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
            </Layout>
        );
    }

}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

function mapStateToProps(state) {
    const events = state.eventList;
    const products = state.productList;
    return {
        events,
        products
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CashDesk))
export default function productListReducer(userList = {
    data: [],
    isFetching: true
}, action) {
    if (action.type === 'FETCH_USERS') {
        return Object.assign({}, userList, {
            isFetching: false,
            data: action.payload.users
        })
    }
    if (action.type === 'ADD_USER') {
        return Object.assign({}, userList, {
            data: [...userList.data, action.payload]
        })
    }
    if (action.type === 'ADD_EVENT_TO_USER') {
        const data = userList.data;
        const user = data.filter((value) => value.id === action.payload.userId)[0];
        user.events = [...user.events, action.payload.eventId];
        return Object.assign({}, userList, {
            data: data
        })
    }
    if (action.type === 'REMOVE_EVENT_TO_USER') {
        const data = userList.data;
        const user = data.filter((value) => value.id === action.payload.userId)[0];
        user.events = user.events.filter(e => e !== action.payload.eventId);
        return Object.assign({}, userList, {
            data: data
        })
    }
    if (action.type === 'DELETE_USER') {
        return Object.assign({}, userList, {
            data: userList.data.filter((value) => value.id !== action.payload.userId)
        })

    }
    return userList;
}
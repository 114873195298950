import {Component} from "react";
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import EditProductModal from "./modals/EditProductModal";


class ProductRow extends Component {

    constructor(props){
        super(props);

        this.state = {open: false}
    }

    render(){
        return (
            <TableRow>
                <TableCell>{this.props.product.name}</TableCell>
                <TableCell>{this.props.category.name}</TableCell>
                <TableCell align="right">{this.props.product.price[0].amount} Kč</TableCell>
                <TableCell align="right">
                    <Button variant="contained" size="small" color="secondary" onClick={this.handleEdit}>
                        Upravit
                    </Button>
                </TableCell>
                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <EditProductModal product={this.props.product} category={this.props.category} categories={this.props.categories} handleClose={this.handleClose}/>
                </Dialog>
            </TableRow>
        );
    }

    handleEdit = e => {
        e.preventDefault();

        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

}

export default ProductRow;
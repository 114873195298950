import Layout from "../components/Layout";
import React, {Component} from "react";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Event from "../components/Event";
import {fetchEvents} from "../actions/eventsActions";
import LoadingScreen from "../components/LoadingScreen";

class EventSelect extends Component {

    componentDidMount() {
        this.props.dispatch(fetchEvents())
    }

    render(){
        if(this.props.events.isFetching){
            return (
                <Layout>
                    <LoadingScreen reason={"Loading events.."}/>
                </Layout>
                )
        }
        return (
            <Layout>
                <Grid container spacing={1}>
                    {
                        this.props.events.data.filter(e => !e.archived).map(e => {
                                return <Event event={e} selected={e.id === this.props.selectedEvent}/>
                            }
                        )
                    }
                </Grid>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const selectedEvent = state.loggedAs == null ? -1 : state.loggedAs.eventId;
    const events = state.eventList;

    return {
        selectedEvent,
        events,
    };
}

export default connect(mapStateToProps)(EventSelect)